import React from "react";
import Input from "../../elements/edit/Input";
import update from "react-addons-update";
import {deletePath, postProductSizesOrder, putProductSizes} from "../../../providers/hooks";
import Nestable from "react-nestable";
import {pluck} from "../../../providers/helpers";



class Sizes extends React.Component {

    state =  {

    }

    changed = (e, index) => {
        const name = e.target.name;
        const value = e.target.value;
        const newSizes = update(this.props.items, {[index]: {[name]: {$set: value}}} );
        this.props.onChange({target: {name: "sizes", value: newSizes}});
    }

    delete = (index) => {
        var r = window.confirm("Želite da obrišete veličinu?");
        if (r !== true) {
            return false;
        }
        const newProps = update(this.props, {items: {$splice: [[index, 1]]}});
        this.props.onChange({target: {name: "sizes", value: newProps.items}});
        const item = this.props.items[index];
        if(item.id)  {
            deletePath({path: "product_sizes." + item.id});
        }
    }

    add = (index) => {
        const item = this.props.items[index];
        if(!item.name)  {
            alert("Unesite ime");
            return false;
        }
        putProductSizes({sizes: this.props.items, product: this.props.product}).then((response) => {
            this.props.onChange({target: {name: "sizes", value: response.data}});
        });
    }

    changedOrder = (items) => {
        postProductSizesOrder({items: pluck(items, "id")}).then((response) => {
            this.props.onChange({target: {name: "sizes", value: items}});
        });
    }

    renderItem = (props) => {
        const item = props.item;
        const i = props.index;
        return <div key={item.id || (i + 1000)} className="p-2">
            <div className="d-flex">
                <div>
                    {props.handler}
                </div>
                <div className="ml-3 flex-grow-1">
                    <div className="row mb-2">
                        <div className="col-md-2">IME</div>
                        <div className="col-md">
                            <Input
                                path={item.id && `product_sizes.${item.id}.name`}
                                onChange={(e) => this.changed(e, i)}
                                value={item.name} name="name" maxLength={191} required />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-2">ŠIFRA</div>
                        <div className="col-md form-inline">
                            <Input
                                path={item.id && `product_sizes.${item.id}.code`}
                                onChange={(e) => this.changed(e, i)}
                                value={item.code} name="code" maxLength={191} />
                        </div>
                    </div>

                    <div className="row mb-2">
                        <div className="col-md-2">CENA</div>
                        <div className="col-md form-inline">
                            <div className="input-group">
                                <Input
                                    path={item.id && `product_sizes.${item.id}.price`}
                                    onChange={(e) => this.changed(e, i)}
                                    value={item.price} name="price"
                                    price={true}
                                    maxLength={191} />
                                <div className="input-group-append">
                                    <span className="input-group-text font-weight-bold">RSD</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/*<div className="row mb-2">
                        <div className="col-md-2">SNIŽENJE</div>
                        <div className="col-md form-inline">
                            <div className="input-group">
                                <Input
                                    path={item.id && `product_sizes.${item.id}.discount`}
                                    onChange={(e) => this.changed(e, i)}
                                    type="number"
                                    value={item.discount} name="discount"
                                    maxLength={100} />
                                    <div className="input-group-append">
                                        <span className="input-group-text font-weight-bold">%</span>
                                    </div>
                            </div>
                        </div>
                    </div>*/}
                    <div className="row mb-2">
                        <div className="col-md-2">SNIŽENA CENA</div>
                        <div className="col-md form-inline">
                            <div className="input-group">
                                <Input
                                    path={item.id && `product_sizes.${item.id}.discount_price`}
                                    onChange={(e) => this.changed(e, i)}
                                    value={item.discount_price} name="discount_price"
                                    price={true}
                                    maxLength={191} />
                                <div className="input-group-append">
                                    <span className="input-group-text font-weight-bold">RSD</span>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="row">
                        <div className="col-md-2"> </div>
                        <div className="col-md">
                            {this.props.product && !item.id &&
                            <button
                                onClick={() => this.add(i)}
                                type="button" className="btn btn-success mr-2">
                                Sačuvajte
                            </button>}
                            <button
                                onClick={() => this.delete(i)}
                                type="button" className="btn btn-danger">
                                Obrišite
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    };

    render = () => {
        return <div>
            <Nestable
                maxDepth={1}
                onChange={this.changedOrder}
                handler={<span className="nestable_icon"><i className="fa fa-arrows" /></span>}
                items={this.props.items}
                renderItem={this.renderItem} />
            </div>
    };
}

export default Sizes;
