import React from "react";
import Loader from "../../elements/Loader";
import {getProducts} from "../../../providers/hooks";
import ProductsItem from "./ProductsItem";
import routes from "../../../app/routes";
import {Link} from "react-router-dom";
import Pagination from "../../elements/Pagination";
import update from "react-addons-update";
import {connect} from "react-redux";
import Nestable from "react-nestable";
import Select from "../../elements/edit/Select";
import {findCategoryBySlug, flatten, getChildrenIds} from "../../../providers/helpers";


class Products extends React.Component {

    state = {
        loading: true,
        products: {},
        selected: [],
        category: "",
        form: {
            all: true,
            page: 1,
            selected: false,
            search: "",
            categories: ""
        }
    }

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newState = update(this.state, {form: {[name]: {$set: value}}});
        this.setState(newState);
        this.load(newState.form);
    }

    select = (e) => {
        const value = e.target.value;
        const checked = e.target.checked;
        let selected = [];
        if(checked)     {
            const newState = update(this.state, {selected: {$push: [parseInt(value)]}});
            this.setState(newState);
            selected = newState.selected;
        } else  {
            selected = this.state.selected.filter(item => item !== parseInt(value));
            this.setState({...this.state, selected: selected});
        }
        if(this.props.drawer.callback) {
            this.props.drawer.callback(selected);
            this.load({...this.state.form, selected: selected});
        }

    }

    renderItem = (props) => {
        const item = props.item;
        const checked = this.state.selected.includes(item.id);
        return <div key={item.id} className="d-flex align-items-center">
            {this.props.inDrawer &&
            <div style={{width: 70}}>
                {checked && <div className="mb-2">{props.handler}</div>}
                <label>
                    <input
                        type="checkbox"
                        onChange={this.select}
                        value={item.id}
                        checked={checked} />
                </label>
            </div>}
            <ProductsItem
                drawer={this.props.drawer}
                key={item.id}
                item={item}
                reload={this.load} />
        </div>
    }

    changedOrder = (items) => {
        const ids = items.map(item => item.id);
        if(this.props.drawer.callback) {
            this.props.drawer.callback(ids);
        }
    }

    renderCategoryItem = (item) => {
        return <option key={item.id} value={item.slug}>
            {" - ".repeat(item.lvl)} {item.name}
        </option>
    }

    changeCategory = (e) => {
        const newState = update(this.state, {category: {$set: e.target.value}, form: {[e.target.name]:
                    {$set: e.target.value ? getChildrenIds(findCategoryBySlug(e.target.value, this.props.categories)) : "" }}});
        this.setState(newState);
        this.load(newState.form);

    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        return <div className="container mt-5 mb-5">
            <div className="mb-3 d-flex form-inline">
                <Link to={routes.product.route()} className="btn btn-primary mr-3">
                    <i className="fa fa-plus" /> Dodajte proizvod
                </Link>

                <input
                    value={this.state.form.search}
                    name="search"
                    className="form-control mr-3"
                    onChange={this.changeField}
                    type="text" placeholder="Pretražite po imenu..." />

                <Select
                    name="categories"
                    value={this.state.category}
                    onChange={this.changeCategory}
                    options={flatten(this.props.categories)}
                    render={this.renderCategoryItem} />
            </div>


            {this.state.products.data.length > 0
                ? <div>
                    <div className="d-flex text-center">
                        {this.props.inDrawer &&
                        <div style={{width: 40}}> </div>}
                        <div style={{width: 120}}><strong> Slika </strong></div>
                        <div className="flex-grow-1"><strong> Informacije </strong></div>
                        <div style={{width: 150}}><strong> Podešavanja </strong></div>
                    </div>

                    <Nestable
                        maxDepth={1}
                        handler={<span className="nestable_icon"><i className="fa fa-arrows" /></span>}
                        onChange={this.changedOrder}
                        items={this.state.products.data}
                        renderItem={this.renderItem} />

                    <Pagination
                        change={this.changeField}
                        page={this.state.form.page}
                        lastPage={this.state.products.last_page} />
                </div>
                : <div> Nema proizvoda </div>}
        </div>
    };

    load = (form) => {
        getProducts(form).then((response) => {
            this.setState({...this.state, loading: false, products: response.data});
        });
    }

    componentDidMount() {
        if(this.props.drawer.selected)      {
            const newForm = {...this.state, selected: this.props.drawer.selected};
            this.setState(newForm);
            this.load(newForm);
            return false;
        }
        this.load(this.state.form);
    }
}
const mapStateToProps = state => ({
    drawer: state.drawers.products,
    categories: state.categories
});
export default connect(mapStateToProps)(Products);
