import React from "react";
import Slider from "../Slider";
import {connect} from "react-redux";
import routes from "../../../app/routes";

class LinkSettings extends React.Component {

    render = () => {
        const node = this.props.node;
       return <Slider header="LINK" className="mb-3">
           <div className="mb-3">
               Link ka stranici:
               <select
                   className="form-control"
                   name="route"
                   onChange={this.props.save}
                   value={node.route || ""}>
                   <option value=""> </option>
                   {this.props.menu.map((item) => {
                       return <option key={item.slug} value={item.slug}>
                           {item.name}
                       </option>
                   })}
               </select>
           </div>
           <div className="mb-3">
               Link ka kategoriji:
               <select
                   className="form-control"
                   name="route"
                   onChange={this.props.save}
                   value={node.route || ""}>
                   <option value=""> </option>
                   {this.props.categories.map((item) => {
                       return <option key={item.slug} value={routes.singleCategory.route(item)}>
                           {item.name}
                       </option>
                   })}
               </select>
           </div>
           <div className="mb-3">
               Ili unesite link:
               <input
                   name="href" value={node.href || ""}
                   onChange={this.props.edit}
                   onBlur={this.props.save}
                   type="text" className="form-control"
                   placeholder="https://google.com" />
           </div>
           <div>
               <label className="m-0">
                   <input
                       name="blank" value={1}
                       onChange={this.props.save}
                       type="checkbox" className="mr-2"
                       checked={node.blank || false} />
                   Otvarajte u novom prozoru
               </label>
           </div>
       </Slider>
    };
}
const mapStateToProps = state => ({
    menu: state.menu,
    categories: state.categories
});
export default connect(mapStateToProps)(LinkSettings);
