import React from "react";
import {connect} from "react-redux";
import SettingsButton from "../../fragments/SettingsButton";
import {getBrands} from "../../../providers/hooks";
import Loader from "../../../views/elements/Loader";
import Content from "./Content";

class Brands extends React.Component {

    state = {
        loading: true,
        all: [],
        brands: [],
        search: ""
    }

    search = (e) => {
        let val = e.target.value;
        this.setState({...this.state, search: val});
        this.load(val);
    };

    render = () => {
        return <div>
            <SettingsButton path={this.props.path} />

            <div className="row justify-content-center mb-4">
                <div className="col-md-6">
                    <input type="text" placeholder="Ime brenda..."
                           value={this.state.search} onChange={this.search}
                           className="form-control bordered bcg-1-2 form-control-lg"/>
                </div>
            </div>

            {this.state.loading
                ? <Loader height={300}/>
                : <Content brands={this.state.brands}/>}
        </div>
    };

    load = (search = "") => {
        getBrands({search: search}).then((response) => {
            let holder = [];
            for (let i = 0; i < response.data.length; i++) {
                let item = response.data[i];
                if(!item.brand[0]) continue;
                let firstLetter = item.brand[0].toLowerCase();
                if (!firstLetter.match(/^[a-zA-Z]+$/)) {
                    continue;
                }
                if (!holder[firstLetter]) {
                    holder[firstLetter] = {};
                    holder[firstLetter]['firstLetter'] = firstLetter;
                    holder[firstLetter]['items'] = [];
                }
                holder[firstLetter]['items'].push(item);
            }
            this.setState({
                ...this.state, loading: false,
                brands: Object.values(holder), all: Object.values(holder)
            });
        });
    }

    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Brands);
