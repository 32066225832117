import React from "react";
import {deletePath, postProductImagesOrder, putProductImages} from "../../../providers/hooks";
import {storage} from "../../../providers/helpers";
import update from "react-addons-update";
import {filesDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import Nestable from "react-nestable";


class Files extends React.Component {

    state =  {
        files: this.props.value || []
    }

    addFiles = (files) => {
        if(files.length < 1) return false;
        const newState = update(this.state, {files: {$push: files}} );
        this.setState(newState);
        const ids = newState.files.map(item => item.id);
        if(this.props.path)     {
            putProductImages({images: ids, product: this.props.product}).then((response) => {
                this.setState({...this.state, files: response.data});
            });
        }
        if(this.props.onChange)    {
            this.props.onChange({target: {name: this.props.name, value: ids}});
        }
    }

    delete = (index) => {
        var r = window.confirm("Želite da obrišete sliku?");
        if (r !== true) {
            return false;
        }
        const newData = update(this.state, {files: {$splice: [[index, 1]]}});
        this.setState(newData);
        if(this.props.path)     {
            deletePath({path: this.props.path + "." + this.state.files[index].pivot.id}).then((response) => {

            })
        }
        if(this.props.onChange)    {
            const ids = newData.files.map(item => item.id);
            this.props.onChange({target: {name: this.props.name, value: ids}});
        }
    }

    changedOrder = (items) => {
        if(!this.props.path) {
            const ids = items.map(item => item.id);
            if(this.props.onChange)    {
                const newState = update(this.state, {files: {$set: items}} );
                this.setState(newState);
                this.props.onChange({target: {name: this.props.name, value: ids}});
            }
        } else  {
            const ids = items.map(item => item.pivot.id);
            postProductImagesOrder({items: ids}).then((response) => {
            });
        }
    }

    renderItem = (props) => {
        const item = props.item;
        const i = props.index;
        return <div key={i} className="">
            <div className="position-relative p-2 bg-white">
                {props.handler}
                <button onClick={() => this.delete(i)} type="button"
                        className="btn btn-sm btn-danger position-absolute" style={{right: 5, top: 5}}>
                    <i className="fa fa-trash" />
                </button>
                <div className="bcg_image" style={{backgroundImage: `url(${storage(item.name)})`}} />
            </div>
        </div>
    };

    render = () => {
        return <>
            <button type="button"
                onClick={() => this.props.filesDrawer({status: true, callback: this.addFiles})}
                className="btn btn-primary">
               <i className="fa fa-plus" /> Dodajte slike
            </button>
            {this.state.files.length > 0 &&
            <div className="mt-3">

                <Nestable
                    className="nestable_inline"
                    maxDepth={1}
                    onChange={this.changedOrder}
                    handler={<span className="nestable_icon position-absolute" style={{right: 30, top: 5}}>
                        <i className="fa fa-arrows" /></span>}
                    items={this.state.files}
                    renderItem={this.renderItem} />

            </div>}
            </>
    };
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    filesDrawer: filesDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(Files);
