import React from 'react';
import {connect} from "react-redux";
import {getFiltersByCategory} from "../../../providers/hooks";
import update from "react-addons-update";


class Filters extends React.Component {

    state = {
        loading: false,
        filters: []
    }

    toggleFilter = (filter) => {
        if(!filter.parent) return;
        if(!this.props.selected[filter.parent])     {
            let newState = update(this.props.selected, {[filter.parent]: {$set: []}});
            newState = update(newState, {[filter.parent]: {$push: [filter.id]}});
            this.props.editField("filters", newState)
            return false;
        }
        var index = this.props.selected[filter.parent].indexOf(filter.id);
        if(index === -1) {
            let newState = update(this.props.selected, {[filter.parent]: {$push: [filter.id]}});
            this.props.editField("filters", newState)
            return false;
        }
        if(this.props.selected[filter.parent].length === 1) {
            let holder = {};
            for(let key in this.props.selected)  {
                if(String(filter.parent) !== String(key))   {
                    holder[key] = this.props.selected[key];
                }
            }
            let newState = update(this.props, {selected: {$set: holder}});
            this.props.editField("filters", newState.selected)
            return false;
        }
        let newState = update(this.props.selected, {[filter.parent]: {$splice: [[index, 1]]}});
        this.props.editField("filters", newState);
    }

    render = () => {
        if(this.state.filters.length === 0) {
            return "";
        }
        const selected = Object.values(this.props.selected).flat();
        //console.log(selected)
        return <div className="link_button">
            {this.state.filters.map((item) => {
                return <div key={item.id}>
                    <strong className="mb-2"> {item.name} </strong>
                    {item.children.map((child) => {
                        return <div key={child.id}>
                           <label className="chbx">
                               <input
                                   className="checbox"
                                   checked={selected.includes(child.id)}
                                   onChange={() => this.toggleFilter(child)}
                                   type="checkbox" /><span />
                                   &nbsp; {child.name}
                           </label>
                        </div>
                    })}
                </div>
            })}
        </div>
    };

    load = () => {
        if(!this.props.category) {
            this.setState({...this.state, filters: []});
            return false;
        }
        if(this.props.category.children.length > 0) {
            this.setState({...this.state, filters: []});
            return false;
        }
        this.setState({...this.state, loading: true});
        getFiltersByCategory({category: this.props.category.id}).then((response) => {
           let holder = {};
           for(let i=0; i<response.data.length; i++)        {
                const item = response.data[i];
                if(!holder[item.parent_name]) {
                    holder[item.parent_name] = {id: item.parent_id, name: item.parent_name, children: []};
                }
               holder[item.parent_name].children.push(item);
           }
           this.setState({...this.state, loading: false, filters: Object.values(holder)});
       })
    }
    componentDidMount() {
        this.load();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.category?.id !== prevProps.category?.id)    {
            this.load();
        }
    }
}
const mapStateToProps = state => ({
    categories: state.categories,
});
export default connect(mapStateToProps)(Filters);
