import React from "react";
import Loader from "../../elements/Loader";
import {connect} from "react-redux";
import {deletePath, getUsers, patchPath} from "../../../providers/hooks";
import Pagination from "../../elements/Pagination";
import update from "react-addons-update";


class Users extends React.Component {

    state = {
        loading: true,
        users: [],
        form: {
            page: 1,
        }
    }

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const newState = update(this.state, {form: {[name]: {$set: value}}});
        this.setState(newState);
        this.load(newState.form);
    }

    delete = (item) => {
        var r = window.confirm("Želite da obrišete korisnika?");
        if (r !== true) {
            return false;
        }
        deletePath({path: `users.${item.id}`}).then((response) => {
            this.load();
        });
    }

    ban = (item) => {
        var r = window.confirm(`Želite da ${item.banned ? "uklonite ban" : "banujete korisnika"}?`);
        if (r !== true) {
            return false;
        }
        let banned = item.banned ? null : 1;
        patchPath({path: `users.${item.id}.banned`, value: banned}).then((response) => {
            this.load();
        })
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }

        return <div className="container mt-5 mb-5">
            <div className="font-weight-bold mb-2 font_2">Registrovani korisnici: </div>
            <div className="mb-3">
                {this.state.users.data.length === 0
                    ? <div className="dark_block"><div className="font-weight-bold">  Nema korisnika.</div></div>
                    : <div>
                        <table className="table">
                        <thead className="font-weight-bold">
                            <tr>
                                <td>Ime i prezime</td>
                                <td>Informacije</td>
                                <td width={150}>Podešavanja</td>
                            </tr>
                        </thead>
                        <tbody>
                            {this.state.users.data.map((item) => {
                                return <tr key={item.id} className="mb-2">
                                    <td>
                                        <div className="mb-2"> Ime: <strong> {item.name} </strong></div>
                                        <div> Prezime: <strong> {item.lastname} </strong></div>
                                    </td>
                                    <td>
                                        <div className="mb-2"> Email: <strong> {item.email} </strong></div>
                                        <div className="mb-2"> Telefon: <strong> {item.phone} </strong></div>
                                        <div className="mb-2"> Adresa: <strong> {item.address} </strong></div>
                                        <div className="mb-2"> Grad: <strong> {item.zip} {item.city} </strong></div>
                                    </td>
                                    <td>
                                        <div className="mb-2">
                                            <button
                                                onClick={() => this.ban(item)}
                                                className="btn btn-sm btn-info">
                                                {item.banned ? "UKLONITE BAN" : "BANUJTE"}
                                            </button>
                                        </div>
                                        <button
                                            onClick={() => this.delete(item)}
                                            className="btn btn-sm btn-danger">
                                            <i className="fa fa-trash" /> OBRIŠITE
                                        </button>
                                    </td>
                                </tr>
                            })}
                        </tbody>
                    </table>
                        <Pagination
                            change={this.changeField}
                            page={this.state.form.page}
                            lastPage={this.state.users.last_page} />
                    </div>}
            </div>
        </div>
    };

    load = (form) => {
        getUsers(form).then((response) => {
            this.setState({...this.state, users: response.data, loading: false});
        });

    }

    componentDidMount() {
        this.load(this.state.form);
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(Users);
