import React from "react";
import {connect} from "react-redux";
import {getOrder, getOrdersCount, patchPath} from "../../../providers/hooks";
import {setCounter} from "../../../store/actions/counterActions";
import Printable from "../../../builder/grids/basket/Printable";
import Loader from "../../elements/Loader";
import routes from "../../../app/routes";
import {order_statuses} from "../../../providers/orderOptions";


class Order extends React.Component {

    state = {
        order: false
    }

    delete = (item) => {
        var r = window.confirm("Želite da obrišete porudzbinu?");
        if (r !== true) {
            return false;
        }
        patchPath({path: `orders.${this.state.order.id}.deleted`, value: 1}).then((response) => {
            this.props.history.push(routes.orders.route);
        });
    }

    changeStatus = (status) => {
        var r = window.confirm(`Želite da promenite status u "${status.title}"?`);
        if (r !== true) {
            return false;
        }
        patchPath({path: `orders.${this.state.order.id}.status`, value: status.value}).then((response) => {
            this.setState({...this.state, order: {...this.state.order, status: status.value}});
        });
    }


    render = () => {
        if(!this.state.order)       {
            return <Loader height={300} />
        }
        return <div className="container mt-5 mb-5">

            <div className="p-3 bg-white">
                <div className="d-flex justify-content-between align-items-center">
                    <div>
                        Promenite status:
                        <div className="mt-1">
                            <div className="btn-group btn-group-toggle" data-toggle="buttons">
                                {Object.values(order_statuses()).map((item) => {
                                    const className = item.value === this.state.order.status ? "btn btn-primary" : "btn btn-outline-primary";
                                    return   <button
                                        onClick={() => this.changeStatus(item)}
                                        className={className} key={item.value}>
                                        {item.title}
                                    </button>
                                })}
                            </div>
                        </div>
                    </div>
                    <button
                        onClick={this.delete}
                        className="btn btn-danger">Obrišite</button>
                </div>

                <Printable order={this.state.order} />
            </div>

        </div>
    };

    componentDidMount() {
        getOrder({id: this.props.match.params.id}).then((response) => {
            this.setState({...this.state, order: response.data});
           if(!response.data.seen)   {
               patchPath({path: `orders.${this.props.match.params.id}.seen`, value: 1}).then((response) => {
                   getOrdersCount().then((response) => {
                       this.props.setCounter({name: "ordersCount", value: response.data});
                   });
               });
           }
        });
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    setCounter: setCounter
};
export default connect(mapStateToProps, mapDispatchToProps)(Order);
