import store from '../store/store';

export function seoReset(item, def) {
    const structuredData = document.querySelector('script[type="application/ld+json"]');
    if(structuredData)      {
        structuredData.remove();
    }

    const glob = store.getState().globals.structure;
    let seo_title = item?.seo_title ? item.seo_title : glob.seo_title;
    let seo_description = item?.seo_description ? item.seo_description : glob.seo_description;
    let seo_keywords = item?.seo_keywords ? item.seo_keywords : glob.seo_keywords;

    if(!seo_title && def)   seo_title = def;
    if(!seo_description && def)   seo_description = def;
    if(!seo_keywords && def)   seo_keywords = def;

    seoTitle(seo_title);
    seoDescription(seo_description);
    seoKeywords(seo_keywords);
}
export function seoTitle(title) {
    if(!title) return false;
    document.querySelector('title').innerHTML = title;
}
export function seoDescription(description) {
    if(!description) return false;
    document.querySelector('meta[name="description"]')
        .setAttribute("content", description);
}
export function seoKeywords(keywords) {
    if(!keywords) return false;
    document.querySelector('meta[name="keywords"]')
        .setAttribute("content", keywords);
}
export function structuredData(data) {
    if(!data) return false;
    let el = document.querySelector('script[type="application/ld+json"]');
    if(!el)     {
        el = document.createElement("script");
        el.type = "application/ld+json";
        document.head.appendChild(el);
    }
    el.innerHTML = JSON.stringify(data);
}
export function seoAll(string) {
    if(!string) return false;
    seoTitle(string);
    seoKeywords(string);
    seoDescription(string);
}