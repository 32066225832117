import React from "react";
import MarginSettings from "../../fragments/settings/MarginSettings";

class DownloadSet extends React.Component {

    render = () => {
        return <>
            <MarginSettings edit={this.props.edit} save={this.props.save} node={this.props.node}/>
        </>
    };

}

export default DownloadSet;
