import React from "react";
import moment from "moment";

class InstallModal extends React.Component {

    state = {
        show: false,
        deferredPrompt: null,
    }

    add = () => {
        this.state.deferredPrompt.prompt();
        this.state.deferredPrompt.userChoice.then((choiceResult) => {
            if (choiceResult.outcome === 'accepted') {
                console.log('User accepted the A2HS prompt');
            } else {
                console.log('User dismissed the A2HS prompt');
            }
            this.setState({...this.state, show: false, deferredPrompt: null});
        });
    }

    later = () => {
        localStorage.setItem("install", moment.utc().format('DD-MM-YYYY HH:mm:ss').toString());
        this.setState({...this.state, show: false});
    }

    render = () => {
        if(!this.state.show) return "";
        return <div className="install_block mb-2 builder">
                <div className="font-weight-bold mb-3">
                    <div className="d-none d-md-block">
                        Dodajte prodavnicu na desktop?
                    </div>
                    <div className="d-md-none">
                        Instalirajte prodavnicu?
                    </div>
                </div>
                <button onClick={this.add} className="btn btn-primary mr-2">Dodajte</button>
                <button onClick={this.later} className="btn btn-outline-primary">Ne sada</button>
            </div>
    }


    componentDidMount() {
        let installStorage = localStorage.getItem("install");
        if(installStorage)      {
            const storage = moment.utc(installStorage, 'DD-MM-YYYY HH:mm:ss')
            const set = moment.duration(moment.utc().diff(storage)).asHours();
            if(isNaN(set))     {
                installStorage = null;
                localStorage.removeItem("install");
            }
            if(set > 160) {
                installStorage = null;
            }
        }
        if(installStorage) {
            return false;
        }
        window.addEventListener('beforeinstallprompt', (e) => {
            console.log(e)
            e.preventDefault();
            this.setState({...this.state, show: true, deferredPrompt: e});
        });
    }
}

export default InstallModal;
