
export default {
    // CMS
    adminLogin: {route: "/cms/login", name: "Ulogujte se"},

    products: {route: "/cms/proizvodi", name: "Proizvodi"},
    product: {route: function (id) {
            if(!id) return "/cms/proizvod";
            return `/cms/proizvod/${id}`
        }, name: "Proizvod"},

    categories: {route: "/cms/kategorije", name: "Kategorije"},
    filters: {route: "/cms/filteri", name: "Filteri"},
    download: {route: "/cms/download", name: "Download"},
    orders: {route: "/cms/orders", name: "Porudžbine"},
    order: {route:  (id) => `/order/${id}`, name: "Porudžbina"},
    storage: {route: "/cms/storage", name: "Galerija"},
    administrators: {route: "/cms/administratori", name: "Administratori"},
    newsletters: {route: "/cms/newsletters", name: "Newsletter"},
    users: {route: "/cms/korisnici", name: "Korisnici"},
    popup: {route: "/cms/popup", name: "Popup"},


    shop: {route: "/shop"},
    basket: {route: "/korpa"},
    thankYou: {route: (code) => `/thank-you/${code}`},
    orderStatus: {route: (code) => `/status-porudzbine/${code}`},
    singleProduct: {route: function (product) {
            const slug = product.slug ? `/${product.slug}` : "";
            return `/proizvod/${product.id}${slug}`
        }},
    singleCategory: {route: function (category) {
            return `/shop/${category.slug}`
        }},

    userLogin: {route: "/user-login"},
    userRegister: {route: "/user-register"},
    userPasswordRecovery: {route: "/user-password-recovery"},
    userDashboard: {route: "/user-dashboard", name: "Informacije o nalogu"},
    userOrders: {route: "/user-orders"},
    userNewsletter: {route: "/user-newsletter"},
}