import React from "react";
import Image from "../../elements/edit/Image";
import {connect} from "react-redux";
import {openDrawer} from "../../../store/actions/drawersActions";
import Checkbox from "../../elements/edit/Checkbox";


class Popup extends React.Component {

    render = () => {
        return (
            <div className="container">
                <div className="bg-white p-3 shadow-sm">
                    <div className="mb-3 pb-2 border-bottom">
                        <Checkbox
                            path={`globals.${this.props.globals.id}.popup`}
                            checked={this.props.globals.popup} name="popup"
                            appendTrue={<span className="green font-weight-bold">POPUP JE AKTIVIRAN</span>}
                            appendFalse={<span className="">POPUP JE UGAŠEN</span>} />
                    </div>

                    Kliknite na sliku da promenite
                    <div className="border mt-2" style={{maxWidth: 500}}>
                        <Image
                            path={`globals.${this.props.globals.id}.popup_image_id`}
                            value={this.props.globals.popup_image_name}
                            name="popup_image_id" />
                    </div>
                </div>

            </div>

        )
    }
}
const mapStateToProps = state => ({
    globals: state.globals
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
};
export default connect(mapStateToProps, mapDispatchToProps)(Popup);
