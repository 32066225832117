import React from "react";
import {connect} from "react-redux";
import SettingsButton from "../../fragments/SettingsButton";
import LiLink from "../../../views/elements/LiLink";
import routes from "../../../app/routes";
import {storage} from "../../../providers/helpers";

class Categories extends React.Component {


    render = () => {
        return <div className="container">
            <SettingsButton path={this.props.path} />

            {this.props.categories.map((category) => {
                return <div key={category.id}>
                    <div className="prewrap builder_title font_7 font-weight-bold pt_20 mb-3">
                        <span> {category.name} </span>
                    </div>
                    <div className="row">
                    {category.children.map((item) => {
                        return <div className="col-md-3 mb-3 p-0 p-md-3" key={item.id}>

                            {item.image_name &&
                            <img src={storage(item.image_name)} className="img-fluid mb-3" alt={item.name} />}

                            <div className="prewrap builder_title font_2">
                                   <span>
                                        <LiLink
                                            onClick={() => this.props.closeDrawer("categories")}
                                            to={routes.singleCategory.route(item)}>

                                       <span className="font-weight-bold"> {item.name} </span>
                                   </LiLink>
                                   </span>
                            </div>

                            <ul className="list-unstyled link_button mt-3">
                                {item.children.map((child) => {
                                    return <li key={child.id} className="d-flex">
                                        <LiLink
                                            onClick={() => this.props.closeDrawer("categories")}
                                            to={routes.singleCategory.route(child)}>
                                            <span className="font-weight-bold"> {child.name} </span>
                                        </LiLink>
                                    </li>
                                })}
                            </ul>
                        </div>
                    })}
                    </div>
                </div>

            })}


        </div>
    };

    load = (search = "") => {

    }

    componentDidMount() {

    }
}
const mapStateToProps = state => ({
    categories: state.categories,
});
const mapDispatchToProps = {
};
export default connect(mapStateToProps, mapDispatchToProps)(Categories);
