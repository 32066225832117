import React from "react";
import {deletePath, putProductPdfs} from "../../../providers/hooks";
import {storage} from "../../../providers/helpers";
import update from "react-addons-update";
import {filesDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";


class Pdfs extends React.Component {

    state =  {
        files: this.props.value || []
    }

    addFiles = (files) => {
        if(files.length < 1) return false;
        const newState = update(this.state, {files: {$push: files}} );
        this.setState(newState);
        const ids = newState.files.map(item => item.id);
        if(this.props.path)     {
            putProductPdfs({pdfs: ids, product: this.props.product}).then((response) => {
                this.setState({...this.state, files: response.data});
            });
        }
        if(this.props.onChange)    {
            this.props.onChange({target: {name: this.props.name, value: ids}});
        }
    }

    delete = (index) => {
        var r = window.confirm("Želite da obrišete fajl?");
        if (r !== true) {
            return false;
        }
        const newData = update(this.state, {files: {$splice: [[index, 1]]}});
        this.setState(newData);
        if(this.props.path)     {
            deletePath({path: this.props.path + "." + this.state.files[index].pivot.id}).then((response) => {

            })
        }
        if(this.props.onChange)    {
            const ids = newData.files.map(item => item.id);
            this.props.onChange({target: {name: this.props.name, value: ids}});
        }
    }

    render = () => {
        return <>
            <button type="button"
                onClick={() => this.props.filesDrawer({status: true, callback: this.addFiles, bucket: 'pdf'})}
                className="btn btn-primary">
                <i className="fa fa-plus" /> Dodajte fajlove
            </button>
            {this.state.files.length > 0 &&
            <div className="row mt-3">
                {this.state.files.map((item, i) => {
                    return <div key={i} className="col-6 col-md-2">
                        <div className="position-relative p-2 bg-white">
                            <button onClick={() => this.delete(i)} type="button"
                                    className="btn btn-sm btn-danger position-absolute" style={{right: 5, top: 5}}>
                                <i className="fa fa-trash" />
                            </button>
                            <div className="bcg_image" style={{backgroundImage: `url(${storage(item.name)})`}} />
                        </div>
                    </div>
                })}
            </div>}
            </>
    };
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    filesDrawer: filesDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(Pdfs);
