import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import SettingsButton from "../../../fragments/SettingsButton";
import routes from "../../../../app/routes";
import update from "react-addons-update";
import Looper from "../../../Looper";
import {postUserRegister} from "../../../../providers/hooks";
import {loginUser} from "../../../../providers/userProvider";
import {setUser} from "../../../../store/actions/userActions";


class Register extends React.Component {

    form = {
        email: "",
        name: "",
        password: "",
        password_confirmation: ""

    }
    state = {
        form: this.form,
        error: {},
        success: false,
        loading: false
    };

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState(update(this.state, {form: {[name]: {$set: value}} }));
    };

    submit = (e) => {
        e.preventDefault();
        this.setState({...this.state, loading: true, error: {}});
        postUserRegister(this.state.form).then((response) => {
            this.setState({...this.state, loading: false, success: true, error: {}});
            let user = loginUser(response, this.state.form.remember);
            if(user) {
                window.location.reload();
            }
        }).catch((error) => {
            this.setState({...this.state, loading: false, error: error, success: false});
        });
    };


    render = () => {
        let form = this.state.form;
        return <div style={{maxWidth: 600, marginLeft: "auto", marginRight: "auto"}}>
                <SettingsButton path={this.props.path} />

                <Looper path={`${this.props.path}.slides.0`} />

                <form action="#" method="POST" onSubmit={this.submit}>
                    <div>
                        <input
                            onChange={this.changeField}
                            name="email"
                            value={form.email}
                            placeholder="Email"
                            type="email"
                            className="mb-3 form-control form-control-lg"
                            required={true}
                        />
                        {this.state.error.email &&
                        <div className="alert alert-danger">{this.state.error.email}</div>}
                    </div>

                    <div>
                        <input
                            name="name"
                            onChange={this.changeField}
                            value={form.name}
                            placeholder="Vaše ime"
                            type="text"
                            className="mb-3 form-control form-control-lg"
                            required={true}
                        />
                        {this.state.error.name &&
                        <div className="alert alert-danger">{this.state.error.name}</div>}
                    </div>

                    <div>
                        <input
                            type="password"
                            onChange={this.changeField}
                            name="password"
                            value={form.password}
                            placeholder="Lozinka"
                            className="form-control mb-3 form-control-lg"
                            required={true}
                        />
                        {this.state.error.password &&
                        <div className="alert alert-danger">{this.state.error.password}</div>}
                    </div>

                    <div>
                        <input
                            name="password_confirmation"
                            type="password"
                            onChange={this.changeField}
                            value={form.password_confirmation}
                            placeholder="Ponovite lozinku"
                            className="form-control mb-3 form-control-lg"
                            required={true}
                        />
                        {this.state.error.password_confirmation &&
                        <div className="alert alert-danger">{this.state.error.password_confirmation}</div>}
                    </div>

                    <button type="submit" disabled={this.state.loading} className="btn btn-lg btn-block btn-primary mb-3">
                        Registrujte se
                    </button>
                </form>

            {this.state.error.message &&
            <div className="alert alert-danger mb-3">
                {this.state.error.message}
            </div>}

            {this.state.success &&
            <div className="alert alert-success mb-3">
                Uspešno ste kreirali nalog.
            </div>}

            <div className="font_1 mb-2">
                <Link to={routes.userLogin.route}>
                    Imate nalog? Ulogujte se.
                </Link>
            </div>
        </div>
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    setUser: setUser
};
export default connect(mapStateToProps, mapDispatchToProps)(Register);