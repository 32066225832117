import React from "react";
import {connect} from "react-redux";
import update from "react-addons-update";
import {getUser, postOrderForm} from "../../../providers/hooks";
import Looper from "../../Looper";
import {payment_method} from "../../../providers/orderOptions";
import Price from "../../theme/Price";
import {emptyBasket} from "../../../store/actions/basketActions";
import routes from "../../../app/routes";


class OrderForm extends React.Component {
    _isMounted = false;

    default = {
        name: "",
        lastname: "",
        email: "",
        phone: "",
        address: "",
        zip: "",
        city: "",
        message: "",
        promo: "",
        payment_method: "1"
    }
    state = {
        form: this.default,
        loading: false,
        errors: {},
    };

    change = (e) => {
        let field = e.target.name;
        let value = e.target.value;
        let newState = update(this.state, {form: {[field]: {$set: value}}});
        this.setState(newState);
    }

    submit = (e) => {
        e.preventDefault();
        this.setState({...this.state, loading: true, errors: {}});
        let basket = this.props.basket.map((item) => {
            return {id: item.id, qty: item.qty, filters: item.filters}
        });
        let form = {...this.state.form, basket: basket};
        if(this.props.user)     {
            form = {...form, user_id: this.props.user.id};
        }
        postOrderForm(form).then((response) => {
            //this.setState({...this.state, loading: false, form: this.default});
            this.props.emptyBasket();
            window.location.href = routes.thankYou.route(response.data.code);
        }).catch((errors) => {
          this.setState({...this.state, errors: errors, loading: false})
        });
    }

    error = (name) => {
        if(this.state.errors[name]) {
            return <div className="alert alert-danger">
                {this.state.errors[name]}
            </div>
        }
    }

    render = () => {
        let form = this.state.form;
        return <div className="container mb-5">
            <form action="/" method="POST" onSubmit={this.submit}>
                <div className="row">
                    <div className="col-md-6 mb-3">
                        <div className="mb-3">
                            <Looper path={`${this.props.path}.title1`} />
                        </div>

                        <div className="primary_font font-italic font_08 mb-3">
                            Polja označena zvezdicom (*) su obavezna.
                        </div>

                        <div className="row">
                            <div className="col-md-6 mb-3">
                                <label className="w-100">
                                    Ime: <span className="primary">*</span><br />
                                    <input
                                        name="name" value={form.name || ""} onChange={this.change}
                                        type="text" className="form-control" required={true} />
                                    {this.error("name")}
                                </label>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="w-100">
                                    Prezime: <span className="primary">*</span><br />
                                    <input
                                        name="lastname" value={form.lastname || ""} onChange={this.change}
                                        type="text" className="form-control" required={true} />
                                </label>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="w-100">
                                    Email: <span className="primary">*</span><br />
                                    <input
                                        name="email" value={form.email || ""} onChange={this.change}
                                        type="email" className="form-control" required={true} />
                                </label>
                            </div>
                            <div className="col-md-6 mb-3">
                                <label className="w-100">
                                    Telefon: <span className="primary">*</span><br />
                                    <input
                                        name="phone" value={form.phone || ""} onChange={this.change}
                                        type="text" className="form-control" required={true} />
                                </label>
                            </div>
                        </div>

                        <label className="w-100 mb-3">
                            Adresa: <span className="primary">*</span><br />
                            <input
                                name="address" value={form.address || ""} onChange={this.change}
                                type="text" className="form-control" required={true} />
                        </label>

                        <div className="row mb-3">
                            <div className="col-4 pr-0">
                                <label className="w-100">
                                    Poštanski br<span className="d-none d-md-inline-block">oj</span>: <span className="primary">*</span><br />
                                    <input
                                        name="zip" value={form.zip || ""} onChange={this.change}
                                        type="text" className="form-control" required={true} />
                                </label>
                            </div>
                            <div className="col-8">
                                <label className="w-100">
                                    Grad: <span className="primary">*</span><br />
                                    <input
                                        name="city" value={form.city || ""} onChange={this.change}
                                        type="text" className="form-control" required={true} />
                                </label>
                            </div>
                        </div>

                        <label className="w-100 mb-3">
                            Poruka:<br />
                            <textarea
                                name="message" value={form.message || ""} onChange={this.change}
                                rows={4} className="form-control" />
                        </label>
                    </div>
                    <div className="col-md-6">
                        <div className="mb-4">
                            <Looper path={`${this.props.path}.title2`} />
                        </div>

                        <div className="mb-4 font_2">
                            <Looper path={`${this.props.path}.title3`} />

                            <div className="mb-2 mt-2">
                                <label className="p-3 w-100 user_bcg">
                                    <input type="radio" name="payment_method" value={1}
                                           onChange={this.change}
                                           checked={form.payment_method === "1"} /> {payment_method(1)}
                                    <div className="font_09 mt-1 font-italic">Plaćanje prilikom preuzimanja paketa kurirskoj službi</div>
                                </label>
                            </div>
                            <div className="mb-2">
                                <label className="p-3 w-100 user_bcg">
                                    <input type="radio" name="payment_method" value={2}
                                           onChange={this.change}
                                           checked={form.payment_method === "2"} /> {payment_method(2)}
                                    <div className="font_09 mt-1 font-italic">Nakon što
                                        završite proces naručivanja na vašu e-mail adresu
                                        će stići informacija sa potrebnim podacima za uplatu.
                                        Uplatu možete izvršiti u svim bankama i poštama Srbije</div>

                                </label>
                            </div>
                        </div>

                        <div className="mb-4">
                            <div className="mb-3">
                                <Looper path={`${this.props.path}.title4`} />
                            </div>
                            <div className="font_primary mb-2 font_4 font-weight-bold">
                                <Price price={this.props.fullPrice} />
                            </div>
                        </div>

                        <div>
                            {this.error("message")}
                        </div>

                        <button disabled={this.state.loading} className="btn btn-lg font-weight-bold btn-success mr-3">
                            <span className="pl-4 pr-4">PORUČITE</span>
                        </button>

                        {this.state.loading &&
                        <span> Procesuiram... </span>}
                    </div>
                </div>
            </form>
        </div>
    };

    componentDidMount() {
        this._isMounted = true;
        if(!this.props.user)    {
            return false;
        }
        getUser().then((response) => {
            if(!this._isMounted) return false;
            const fill = {...this.default, ...response.data};
            const newState = update(this.state, {form: {$set: fill}})
            this.setState(newState);
        });
    }
    componentWillUnmount() {
        this._isMounted = false;
    }
}

const mapStateToProps = state => ({
    user: state.user,
    basket: state.basket,
    categories: state.categories
});
const mapDispatchToProps = {
    emptyBasket: emptyBasket
};
export default connect(mapStateToProps, mapDispatchToProps)(OrderForm);
