import React from "react";
import {deletePath, putProductSpecifications} from "../../../providers/hooks";
import update from "react-addons-update";
import Input from "../../elements/edit/Input";


class Specifications extends React.Component {

    state =  {

    }

    changed = (e, index) => {
        const name = e.target.name;
        const value = e.target.value;
        const newSpecs = update(this.props.specifications, {[index]: {[name]: {$set: value}}} );
        this.props.onChange({target: {name: "specifications", value: newSpecs}});
    }

    delete = (index) => {
        var r = window.confirm("Želite da obrišete?");
        if (r !== true) {
            return false;
        }
        const newProps = update(this.props, {specifications: {$splice: [[index, 1]]}});
        this.props.onChange({target: {name: "specifications", value: newProps.specifications}});
        const item = this.props.specifications[index];
        if(item.id)  {
            deletePath({path: "product_specifications." + item.id});
        }
    }

    add = (index) => {
        const item = this.props.specifications[index];
        if(!item.name)  {
            alert("Unesite ime");
            return false;
        }
        putProductSpecifications({specifications: this.props.specifications, product: this.props.product}).then((response) => {
            this.props.onChange({target: {name: "specifications", value: response.data}});
        });
    }

    render = () => {
        return <div>
            {this.props.specifications.map((item, i) => {
                return <div key={item.id || (i + 1000)} className="mb-3 d-flex align-items-baseline">
                    <div className="row flex-grow-1 mr-2">
                        <div className="col">
                            <div className="mb-1">Naziv</div>
                            <div>
                                <Input
                                    path={item.id && `product_specifications.${item.id}.name`}
                                    onChange={(e) => this.changed(e, i)}
                                    value={item.name} name="name" maxLength={191} required />
                            </div>
                        </div>
                        <div className="col">
                            <div className="mb-1">Vrednost</div>
                            <div>
                                <Input
                                    path={item.id && `product_specifications.${item.id}.value`}
                                    onChange={(e) => this.changed(e, i)}
                                    value={item.value} name="value" maxLength={191} required />
                            </div>
                        </div>
                    </div>
                    <div>
                        <div className="mb-1"> &nbsp;</div>
                        {this.props.product && !item.id &&
                        <button
                            onClick={() => this.add(i)}
                            type="button" className="btn btn-success mr-2">
                            Sačuvajte
                        </button>}
                        <button
                            onClick={() => this.delete(i)}
                            type="button" className="btn btn-danger">
                            Obrišite
                        </button>
                    </div>
                </div>
            })}
        </div>
    };
}

export default Specifications;
