import React from "react";


class Qty extends React.Component   {

    state = {
        message: ""
    }

    changeQtyE = (e) => {
        let val = e.target.value;
        this.changeQty(val);
    }

    minus = () => {
        let value = 0;
        if(this.props.value) value = this.props.value;
        this.changeQty( value- 1);
    }
    plus = () => {
        let value = 0;
        if(this.props.value) value = this.props.value;
        this.changeQty(value + 1);
    }

    changeQty = (val) => {
        let max = 10;
        if(this.props.max)       {
            max = parseInt(this.props.max);
        }
        if(val === "")  {
            this.props.onChange(val);
            this.setState({...this.state, message: ""});
            return false;
        }
        if(val < 1) return false;
        if(val > max) {
            this.setState({...this.state, message: "Max. na stanju: " + max});
            return false;
        }
        this.props.onChange(val);
        this.setState({...this.state, message: ""});
    };


    render = () => {
        return <div className="position-relative">
            <div className="input-group mr-1" style={{width: 156}}>
                <div className="input-group-prepend">
                    <button onClick={this.minus}
                            className="btn btn-outline-secondary btn-sm pl-3 pr-3" type="button">
                        <i className="fa fa-minus" />
                    </button>
                </div>
                <input
                    type="number"
                    className="form-control font-weight-bold form-control"
                    value={this.props.value}
                    onChange={this.changeQtyE} />
                <div className="input-group-append">
                    <button onClick={this.plus}
                            className="btn btn-outline-secondary btn-sm pl-3 pr-3" type="button">
                        <i className="fa fa-plus" />
                    </button>
                </div>
            </div>
            {this.state.message &&
            <div className="position-absolute primary">
                {this.state.message}
            </div>}
        </div>


    }
}
export default Qty;
