import React from "react";
import Looper from "../../Looper";
import Arrows from "./Arrows";
import {connect} from "react-redux";
import {randString} from "../../../providers/helpers";

class Slider extends React.Component {

    state = {
        index: 0,
        loaded: [],
        count: parseInt(this.props.node.blockCount),
        rand: randString()
    }
    slider = null;
    isDown = false;
    startX = null;
    scrollLeft = null;
    auto = true;
    autoSide = "right";

    render = () => {
        return <div className="h-100 position-relative arrows_hoverable">
            {this.state.count > 1 &&
            <Arrows
                showLeft={this.state.index !== 0}
                showRight={this.state.count > (this.state.index+1)}
                left={() => this.scrolling(null, "left")}
                right={() => this.scrolling(null, "right")} />}

            <div className={(this.props.selected && this.props.selected.startsWith(this.props.path))
                ? `index_slider_${this.state.rand} scroll_slider index_slider_selected`
                : `index_slider_${this.state.rand} scroll_slider`}>
                <div className="scroll_slider_wrapper">
                    {Array.from(Array(this.state.count).keys()).map((i) => {
                        if(this.state.loaded.includes(i))  {
                            return <div key={i} className="w-100 slide">
                                <Looper
                                    path={`${this.props.path}.slides.${i}`} />
                            </div>
                        }
                        if(this.state.index === i)      {
                            if(!this.state.loaded.includes(i))  {
                                this.state.loaded.push(i);
                            }
                            return <div key={i} className="w-100 slide">
                                <Looper
                                    path={`${this.props.path}.slides.${i}`}  />
                            </div>
                        }
                        return <div key={i} className="w-100 slide">
                            <Looper
                                path={`${this.props.path}.slides.${i}`}  />
                        </div>
                    })}
                </div>
            </div>
        </div>
    };


    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    scrolling = async (pageX, side = false) =>  {
        if(window.innerWidth < 760 && !side) return false;
        let width = this.slider.clientWidth;
        let ticks = 50;
        let time = 300;
        let index = this.state.index;
        if(side === "left")        {
            if(this.state.index > 0)  {
                index = this.state.index - 1;
            }
            time = 300;
            ticks = 10;
        }
        else if(side === "right")        {
            if(this.state.index < this.state.count-1)  {
                index = this.state.index + 1;
            }
            time = 300;
            ticks = 15;
        }
        else if((this.startX - pageX) > 0)  {
            index = Math.ceil(this.slider.scrollLeft/width);
        }
        else        {
            index = Math.floor(this.slider.scrollLeft/width);
        }
        this.setState({...this.state, index: index});
        const dest = index * width
        const curr = this.slider.scrollLeft;
        const perc = (dest - curr) / ticks;
        for(let i=1; i< (ticks+1); i++)     {
            if(this.isDown) break;
            await this.sleep(time/ticks);
            this.slider.scrollLeft = curr + (i * perc);
        }
    }

    componentDidMount() {
        this.slider = document.querySelector(`.index_slider_${this.state.rand}`);
        setInterval(() => {
            if(this.auto)   {
                if(this.state.index === 0)        {
                    this.autoSide = "right";
                }
                else if((this.state.count-1) === this.state.index)      {
                    this.autoSide = "left";
                }
                this.scrolling(null, this.autoSide);
            }
        }, 5000);

        this.slider.addEventListener('click', (e) => {
            if(Math.abs((this.startX + this.slider.offsetLeft) - e.pageX) > 3)  {
                e.preventDefault();
            }
        });
        this.slider.addEventListener('mousedown', (e) => {
            this.isDown = true;
            this.startX = e.pageX - this.slider.offsetLeft;
            this.scrollLeft = this.slider.scrollLeft;
        });
        /*this.slider.addEventListener('mouseleave', (e) => {
            this.isDown = false;
            this.scrolling(e.pageX);
        });*/
        this.slider.addEventListener('mouseup', (e) => {
            this.isDown = false;
            this.auto = false;
            if(Math.abs((this.startX + this.slider.offsetLeft) - e.pageX) > 3)  {
                this.scrolling(e.pageX);
            }
        });
        this.slider.addEventListener('touchend', (e) => {
            this.isDown = false;
            this.auto = false;
            this.scrolling(e.changedTouches[0].clientX);
        });
        this.slider.addEventListener('touchstart', (e) => {
            this.isDown = true;
            this.startX = e.touches[0].clientX;
            this.setState({...this.state, loaded: [0,1,2,3,4,5,6,7,8,9,10]});
        });
        this.slider.addEventListener('scroll', (e) => {
            //console.log("asfdasdfas")
        });
        this.slider.addEventListener('mousemove', (e) => {
            if(!this.isDown) return;
            e.preventDefault();
            const x = e.pageX - this.slider.offsetLeft;
            const walk = (x - this.startX) ; //scroll-fast
            this.slider.scrollLeft = this.scrollLeft - walk;
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.node.blockCount !== prevProps.node.blockCount)        {
            this.setState({...this.state, count: parseInt(this.props.node.blockCount)})
        }
    }
}
const mapStateToProps = state => ({
    selected: state.selected
});
export default connect(mapStateToProps)(Slider);
