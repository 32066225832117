import React from "react";
import {patchPath} from "../../../providers/hooks";

class Checkbox extends React.Component {

    state = {
        checked: !!this.props.checked
    }

    changed = (e) => {
        var checked = e.target.checked;
        var val = e.target.value;
        this.setState({...this.state, checked: checked});
        if(!checked)    {
            val = null;
        }
        if(this.props.path)     {
            patchPath({path: this.props.path, value: val}).then((response) => {

            })
        }
        if(this.props.onChange)    {
            this.props.onChange(e);
        }
    }

    render = () => {
        return <label>
            <input
                type="checkbox"
                onChange={this.changed}
                value={this.props.value || 1}
                checked={this.state.checked}
                name={this.props.name || ""}
                className="mr-1" />

            {this.state.checked && this.props.appendTrue}
            {!this.state.checked && this.props.appendFalse}
            </label>
    };
}

export default Checkbox;
