import React from "react";
import {connect} from "react-redux";
import Drawer from "../../../views/framework/Drawer";
import {closeDrawer} from "../../../store/actions/drawersActions";


class InfoDrawer extends React.Component {

    render = () => {
        const globals = this.props.globals.structure;
        return  <Drawer
           anchor="bottom"
           maxWidth="100%"
           show={this.props.drawer.status}
           close={() => this.props.closeDrawer('info')}>

           <div className="link_button font-weight-bold">
               {globals.phone &&
               <div className="mb-2">
                   <a href={`tel:${globals.phone}`}>
                      <i className="fa fa-mobile mr-2" /> {globals.phone}</a>
               </div>}
               {globals.email &&
               <div className="mb-2">
                   <a href={`mailto:${globals.email}`}>
                       <i className="fa fa-envelope mr-2" /> {globals.email}</a>
               </div>}
               {globals.facebook &&
               <div className="mb-2">
                   <a href={globals.facebook} target="_blank" rel="noopener noreferrer">
                       <i className="fa fa-facebook mr-2" /> {globals.facebook}</a>
               </div>}
               {globals.youtube &&
               <div className="mb-2">
                   <a href={globals.youtube} target="_blank" rel="noopener noreferrer">
                       <i className="fa fa-youtube-play mr-2" /> {globals.youtube}</a>
               </div>}
               {globals.twitter &&
               <div className="mb-2">
                   <a href={globals.twitter} target="_blank" rel="noopener noreferrer">
                       <i className="fa fa-twitter mr-2" /> {globals.twitter}</a>
               </div>}
               {globals.instagram &&
               <div className="mb-2">
                   <a href={globals.instagram} target="_blank" rel="noopener noreferrer">
                       <i className="fa fa-instagram mr-2" /> {globals.instagram}</a>
               </div>}
           </div>
       </Drawer>
    };
}
const mapStateToProps = state => ({
    drawer: state.drawers.info,
    globals: state.globals,
});
const mapDispatchToProps = {
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(InfoDrawer);
