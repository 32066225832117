import React from "react";
import ProductRender from "../theme/ProductRender";
import Arrows from "./slider/Arrows";
import {getProducts} from "../../providers/hooks";
import {connect} from "react-redux";
import {setSelected} from "../../store/actions/selectedActions";
import Looper from "../Looper";
import SettingsButton from "../fragments/SettingsButton";


class ProductsCarousel extends React.Component   {

    state = {
        id: Math.floor(Math.random() * 10000),
        products: [],
        scrollLeft: 0,
        scrollRight: true,
    }
    slider = null;
    isDown = false;
    startX = null;


    render = () => {
        const classname = (this.props.admin && !this.props.preview) ? "position-relative arrows_hoverable" : "position-relative arrows_hoverable";
        return <div className={classname}>
            <SettingsButton path={this.props.path} />

            {this.state.products.length > 0 &&
            <Arrows
                showLeft={true}
                showRight={this.state.scrollRight}
                left={() => this.scrolling(null, "left")}
                right={() => this.scrolling(null, "right")} />}

            <div className={"carousel_slider carousel_slider_" + this.state.id}>
                <div className="carousel_slider_wrapper">
                    {this.state.products.length > 0
                        ? this.state.products.map((item, i) => {
                            return <div key={item.id} className="carousel_product">
                                <ProductRender product={item}
                                               append={<Looper path={`${this.props.path}.${item.id}`} />} />
                            </div>})
                        : <div
                            className="p-4 builder_element_empty w-100 pointer">
                            Niste dodali nijedan proizvod
                        </div>}
                </div>
            </div>
        </div>
    };

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    scrolling = async (pageX, side = false) =>  {
        if(!side) return false;
        //if(window.innerWidth < 760) return false;
        let width = this.slider.clientWidth;
        let ticks = 50;
        let time = 300;
        let dest;
        if(side === "left")        {
            time = 300;
            ticks = 10;
            dest = (this.state.scrollLeft - width) < 0 ? 0 : this.state.scrollLeft - width;
        }
        else if(side === "right")        {
            time = 300;
            ticks = 15;
            const productWidth = this.slider.firstChild.firstChild.clientWidth;
            const innerWidth = productWidth * this.state.products.length;
            if((this.state.scrollLeft + width) > innerWidth)        {
                dest = innerWidth - width;
            } else  {
                dest = this.state.scrollLeft + width;
            }
        }
        this.setState({...this.state, scrollLeft: dest});
        const curr = this.slider.scrollLeft;
        const perc = (dest - curr) / ticks;
        for(let i=1; i< (ticks+1); i++)     {
            if(this.isDown) break;
            await this.sleep(time/ticks);
            this.slider.scrollLeft = curr + (i * perc);
        }
    }

    load = () => {
        if(this.props.node.products?.length === 0) {
            this.setState({...this.state, products: []});
            return false;
        }
        getProducts({ids: this.props.node.products}).then((response) => {
            this.setState({...this.state, products:
               response.data.data
                   });
        })
    }

    componentDidMount()     {
        this.load();
        this.slider = document.querySelector('.carousel_slider_' + this.state.id);
        this.slider.addEventListener('click', (e) => {
            if(Math.abs((this.startX + this.slider.offsetLeft) - e.pageX) > 3)  {
                e.preventDefault();
            }
        });
        this.slider.addEventListener('mousedown', (e) => {
            this.isDown = true;
            this.startX = e.pageX - this.slider.offsetLeft;
            this.setState({...this.state, scrollLeft: this.slider.scrollLeft});
        });
        this.slider.addEventListener('mouseup', (e) => {
            this.isDown = false;
            this.setState({...this.state, scrollLeft: this.slider.scrollLeft});
        });
        this.slider.addEventListener('touchend', (e) => {
            this.isDown = false;
            this.setState({...this.state, scrollLeft: this.slider.scrollLeft});
        });
        this.slider.addEventListener('touchstart', (e) => {
            this.isDown = true;
            this.startX = e.touches[0].clientX;
            if(!this.state.loadALl)
                this.setState({...this.state, loadAll: true});
        });
        this.slider.addEventListener('scroll', (e) => {
            //console.log("asfdasdfas")
        });
        this.slider.addEventListener('mousemove', (e) => {
            if(!this.isDown) return;
            e.preventDefault();
            const x = e.pageX - this.slider.offsetLeft;
            const walk = (x - this.startX) ; //scroll-fast
            this.slider.scrollLeft = this.state.scrollLeft - walk;
        });
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if(JSON.stringify(this.props.node.products) !== JSON.stringify(prevProps.node.products))      {
            this.load();
        }
    }
}
const mapStateToProps = state => ({
    admin: state.admin,
    preview: state.preview
});
const mapDispatchToProps = {
    setSelected: setSelected
}
export default connect(mapStateToProps, mapDispatchToProps)(ProductsCarousel);
