import React from "react";
import Loader from "../../elements/Loader";
import {deleteFiles, getFiles} from "../../../providers/hooks";
import Uploader from "./Uploader";
import update from "react-addons-update";
import {randString, storage} from "../../../providers/helpers";
import {connect} from "react-redux";
import {filesDrawer} from "../../../store/actions/drawersActions";
import Pagination from "../../elements/Pagination";


class Storage extends React.Component {

    state = {
        loading: true,
        storage: {},
        files: [],
        selected: [],
        form: {
            page: 1,
            search: "",
            bucket: this.props.drawer.bucket || "file",
        }
    }

    toggleSelected = (item) => {
        const find = this.state.selected.find(select => select.id === item.id);
        if(!find)       {
            this.setState(update(this.state, {selected: {$push: [item]}} ));
        }
        else        {
            const newSelected = this.state.selected.filter(select => select.id !== item.id);
            this.setState({...this.state, selected: newSelected});
        }
    }

    add_files = (e) => {
        if (e.target.files.length === 0) {
            alert('Niste uneli fajl.');
            return false;
        }
        for(var i=0; i<e.target.files.length; i++) {
            e.target.files[i].id = randString();
        }
        this.setState(update(this.state, {files: {$push: Object.values(e.target.files)} }));
        e.target.value = "";
    }

    selectItems = () => {
        if(this.props.drawer.callback) this.props.drawer.callback(this.state.selected);
        this.props.filesDrawer({status: false});
    }

    deleteItems = () => {
        var r = window.confirm("Želite da obrišete fajlove?");
        if (r !== true) {
            return false;
        }
        deleteFiles({files: this.state.selected}).then((response) => {
            this.setState({...this.state, selected: []});
            this.load();
        })
    }

    route = (form = false) => {
        if(!form) form = this.state.form;
        let params = [];
        for(let key in form) {
            let value = form[key];
            if(!value) continue;
            if(typeof value === "object")    {
                value = JSON.stringify(value);
            }
            if(Array.isArray(value))    {
                value = JSON.stringify(value);
            }
            params.push(`${key}=${value}`);
        }
        if(params.length === 0) {
            return "";
        }
        return "?" + params.join("&");
    }

    changeField = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        let newState;
        if(name === "page") {
            newState = update(this.state, {form: {[name]: {$set: value}}});
        } else  {
            newState = update(this.state, {form: {[name]: {$set: value}, page: {$set: 1}}});
        }
        this.setState(newState);
        if(this.props.history) {
            this.props.history.push(this.route(newState.form));
        }
        this.load(newState.form);
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }
        const selected = this.state.selected.map(item => item.id);
        return <div className="container-fluid mt-3 mb-5">
            <div className="d-flex align-items-center form-inline dark_block">
                <div className="mr-4">
                    <input type="file" multiple name="file" onChange={this.add_files} />
                </div>

                <div>
                    <input
                        value={this.state.form.search}
                        name="search"
                        className="form-control"
                        onChange={this.changeField}
                        type="text" placeholder="Pretražite po imenu..." />
                </div>
            </div>



            {this.state.files.length > 0 &&
            <div className="mt-3 d-flex">
                {this.state.files.map((item) => {
                    return <Uploader
                        bucket={this.state.form.bucket}
                        key={item.id}
                        file={item}
                        reload={this.load} />
                })}
            </div>}

            <div className="mt-3">
                {this.state.storage.data.length > 0
                    ? <div>
                        <div className="row m-0">
                            {this.state.storage.data.map((item) => {
                                return <div key={item.id} className="col-6 col-md-1 p-0">
                                    <div onClick={() => this.toggleSelected(item)}
                                         className={selected.includes(item.id) ? "storage_file selected" : "storage_file"}>
                                        <i className="fas fa-check-square" />
                                        <div className="bcg_image" style={{backgroundImage: `url(${storage(item.name)})`}} />
                                    </div>
                                    {item.orig_name}
                                </div>
                            })}
                        </div>

                        <Pagination
                            change={this.changeField}
                            page={this.state.form.page}
                            lastPage={this.state.storage.last_page} />
                    </div>
                    : <div> Prazno </div>}

                {this.state.selected.length > 0 &&
                <div className="storage_footer">
                    {this.props.drawer.callback &&
                    <button onClick={this.selectItems} className="btn btn-primary mr-2">
                        Selektujte
                    </button>}
                    <button onClick={this.deleteItems} className="btn btn-danger">
                        Obrišite
                    </button>
                </div>}
            </div>
        </div>
    };

    load = (form) => {
        if (!form) form = this.state.form;
        getFiles(form).then((response) => {
            this.setState({...this.state, loading: false, storage: response.data});
        });
    }

    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({
    drawer: state.drawers.files
});
const mapDispatchToProps = {
    filesDrawer: filesDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(Storage);
