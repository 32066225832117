import React from 'react';
import {calculateDiscount, storage} from "../../providers/helpers";
import {Link} from "react-router-dom";
import Price from "./Price";
import routes from "../../app/routes";


class ProductRender extends React.Component   {

    render = () => {
        const product = this.props.product;
        //const categorySlug = product.categories[0] ? product.categories[0].slug + "/" : "";
        return <div className="position-relative p-2">
            <div className="product_tags">
                {product.min_price === product.max_price && product.discount_price && product.price &&
                <div>-{calculateDiscount(product)}%</div>}
                {product.new && <div>NOVO</div>}
                {product.action && <div>AKCIJA</div>}
            </div>
            <div className="mb-2">
                <Link to={routes.singleProduct.route(product)}>
                    <div className="bcg_image" style={{backgroundImage: `url(${storage(product.image_name)})`}} />
                </Link>
            </div>
            <div className="d-inline">
                <div className="text-center mb-1">
                    <div className="font_primary font-weight-bold font_2">
                        <Price item={product} />
                    </div>
                    {product.min_price === product.max_price && product.discount_price &&
                    <div>
                        <del className="gray font_1"><Price price={product.price} /></del>
                    </div>}
                </div>
                <div className="text-center font-weight-bold">
                    <Link to={routes.singleProduct.route(product)}>
                        {product.name}
                    </Link>
                </div>
                {this.props.append}
            </div>
      </div>
    };
}

export default ProductRender;
