import React from "react";
import Slider from "../Slider";
import Nestable from "react-nestable";
import Modal from "../../../views/framework/Modal";
import {setGlobals} from "../../../store/actions/globalsActions";
import {setPage} from "../../../store/actions/pageActions";
import {connect} from "react-redux";
import {Path} from "../../Path";
import dotProp from "dot-prop-immutable"
import {patchPath} from "../../../providers/hooks";
import Classes from "../Classes";


class GridSettings extends React.Component {

    state = {
        orderModal: false,
    }

    renderItem = (props) => {
        return <div className="d-flex justify-content-between align-items-center">
            <div>
                {props.collapseIcon}
                {props.handler}
                {props.item.name}
            </div>
        </div>
    };

    collapseIcons = ({ isCollapsed }) => {
        if(isCollapsed) return <span className="nestable_icon"><i className="fa fa-plus" /></span>
        return <span className="nestable_icon"><i className="fa fa-minus" /></span>;
    };

    changedOrder = (items) => {
        const slides = this.props.node.slides;
        let holder = items.map((it) => {
           return slides[it.id];
        });
        const pathObj =  Path(this.props.selected);
        if(pathObj.globals) {
            const newState = dotProp.set(this.props.globals, `${pathObj.path}.slides`, holder);
            this.props.setGlobals(newState);
            patchPath({path: `globals.${this.props.globals.id}.structure`, value: newState.structure});
        } else {
            const newState = dotProp.set(this.props.page, `${pathObj.path}.slides`, holder);
            this.props.setPage(newState);
            patchPath({path: `pages.${this.props.page.id}.structure`, value: newState.structure});
        }
    }

    render = () => {
        const node = this.props.node;
        let nestable = [];
        if(node.slides)     {
            nestable = node.slides.map((item, i) => {
                return {id: i, name: `Slajd ${i+1}`}
            });
        }

       return  <Slider open={true} header="BLOKOVI" className="mb-3">

           <Modal
               open={this.state.orderModal}
               close={() => this.setState({...this.state, orderModal: false})}>


               <Nestable
                   collapsed={true}
                   maxDepth={1}
                   onChange={this.changedOrder}
                   renderCollapseIcon={this.collapseIcons}
                   handler={<span className="nestable_icon"><i className="fa fa-arrows" /></span>}
                   items={nestable}
                   renderItem={this.renderItem}
               />
           </Modal>

           <div className="mb-3">
               <button
                   onClick={() => this.setState({...this.state, orderModal: true})}
                   className="btn btn-info">
                   <i className="fa fa-bars mr-1" /> Promenite raspored
               </button>
           </div>
           <div className="mb-3">
               Broj blokova:
               <select
                   className="form-control"
                   name="blockCount"
                   onChange={this.props.save}
                   value={node.blockCount || 2}>
                   {[1,2,3,4,5,6].map((item) => {
                       return <option key={item} value={item}>
                           {item}
                       </option>
                   })}
               </select>
           </div>

           {parseInt(node.blockCount) === 2 &&
           <div className="mb-3">
               Odnos blokova:
               <select
                   className="form-control"
                   name="proportion"
                   onChange={this.props.save}
                   value={node.proportion}>
                   {[
                       {col: 6, name:"Pola : Pola"},
                       {col: 5, name:"5 : 7"},
                       {col: 4, name: "1 trećina : 2 trećina"},
                       {col: 3, name: "1 četvrtina : 3 četvrtina"},
                       {col: 8, name: "2 trećina : 1 trećina"},
                       {col: 9, name: "3 četvrtina : 1 četvrtina"},
                   ].map((item) => {
                       return <option key={item.name} value={item.col}>
                           {item.name}
                       </option>
                   })}
               </select>
           </div>}

           <Classes
               className="mt-3"
               title="Pozicija teksta:"
               name="textAlign"
               classes={node.classes}
               onChange={this.props.save} />
       </Slider>
    };
}
const mapStateToProps = state => ({
    page: state.page,
    globals: state.globals,
    selected: state.selected
});
const mapDispatchToProps = {
    setGlobals: setGlobals,
    setPage: setPage,
};
export default connect(mapStateToProps, mapDispatchToProps)(GridSettings);
