import React from "react";
import Loader from "../../elements/Loader";
import {filesDrawer} from "../../../store/actions/drawersActions";
import {connect} from "react-redux";
import {deletePath, getDownload, putDownload, server} from "../../../providers/hooks";
import Input from "../../elements/edit/Input";
import Select from "../../elements/edit/Select";
import pdf from "../../../assets/images/pdf.png"


class Download extends React.Component {

    state = {
        loading: true,
        download: []
    }

    addFiles = (files) => {
        if(files.length < 1) return false;
        const ids = files.map(item => item.id);
        putDownload({ids: ids}).then((response) => {
            this.load();
        });
    }

    delete = (item) => {
        var r = window.confirm("Želite da obrišete fajl?");
        if (r !== true) {
            return false;
        }
        deletePath({path: `download.${item.id}`}).then((response) => {
            this.load();
        });
    }

    renderType = (item) => {
        return <option key={item.id} value={item.value}> {item.value} </option>
    }

    render = () => {
        if(this.state.loading)      {
            return <Loader />
        }

        return <div className="container mt-5 mb-5">
            <div className="mb-3">
                <button
                    onClick={() => this.props.filesDrawer({status: true, callback: this.addFiles, bucket: 'pdf'})}
                    className="btn btn-primary">
                    Dodajte PDF
                </button>
            </div>

            <table className="table">
                <tbody>
                {this.state.download.map((item) => {
                    return <tr key={item.id}>
                        <td className="text-center">
                            <a rel="noopener noreferrer" href={server('fajlovi/' + item.file_name)} target="_blank">
                                <div>
                                    <img src={pdf} alt={item.orig_name} width={80} />
                                </div>
                                {item.orig_name}
                            </a>
                        </td>
                        <td>
                            <div className="mb-2">
                                <label className="d-block d-flex align-items-center form-inline">
                                    <div className="mb-2" style={{width: 120}}> Naziv: </div>
                                    <Input
                                        size="sm"
                                        path={`download.${item.id}.name`}
                                        value={item.name} name="name" maxLength={191} />
                                </label>
                            </div>

                            <div className="mb-2">
                                <label className="d-block d-flex align-items-center form-inline">
                                    <div className="mb-2" style={{width: 120}}> Brend: </div>
                                    <Input
                                        size="sm"
                                        path={`download.${item.id}.brand`}
                                        value={item.brand} name="brand" maxLength={191} />
                                </label>
                            </div>


                            <div className="mb-3 d-flex align-items-center form-inline">
                                <div className="mb-2" style={{width: 120}}> Tip: </div>
                                <Select
                                    size="sm"
                                    name="type"  value={item.type}
                                    path={`download.${item.id}.type`}
                                    options={[{id: 1, value: "Atesti"}, {id: 2, value: "Cenovnici"}, {id: 3, value: "Uputstvo za upotrebu"}]}
                                    render={this.renderType}/>
                            </div>
                        </td>
                        <td width={100}>
                            <button
                                onClick={() => this.delete(item)}
                                className="btn btn-danger">
                                Obrišite
                            </button>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    };

    load = () => {
        getDownload().then((response) => {
            this.setState({...this.state, download: response.data.download, loading: false});
        })

    }

    componentDidMount() {
        this.load();
    }
}
const mapStateToProps = state => ({

});
const mapDispatchToProps = {
    filesDrawer: filesDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Download);
