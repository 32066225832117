import React from "react";
import {grids} from "./exports";
import {connect} from "react-redux";
import dotProp from "dot-prop-immutable";
import {setSelected} from "../store/actions/selectedActions";
import {Path} from "./Path";
import {setPage} from "../store/actions/pageActions";
import {getGlobals, getPage, patchPath} from "../providers/hooks";
import {setGlobals} from "../store/actions/globalsActions";
import {merge} from "../providers/helpers";
import {openDrawer} from "../store/actions/drawersActions";


class Grid extends React.Component {

    state = {
        pathObj: Path(this.props.path),
    }

    selectedGridModal = (element) => {
        if(this.state.pathObj.globals)      {
            getGlobals().then((response) => {
                const newState = this.insertNode(response.data, element);
                patchPath({path: `globals.${this.props.globals.id}.structure`, value: newState.structure});
                this.props.setGlobals(newState);
            });
        } else  {
            getPage({slug: this.props.page.slug}).then((response) => {
                const newState = this.insertNode(response.data, element);
                this.props.setPage(newState);
                patchPath({path: `pages.${this.props.page.id}.structure`, value: newState.structure});
            });
        }
    }

    insertNode = (overrideState, element) => {
        let newState = this.state.pathObj.globals ? this.props.globals : this.props.page;
        newState = merge(newState, overrideState);
        const position = this.props.position + 1;
        let nodes = dotProp.get(newState, this.state.pathObj.path, []);
        if(!nodes) nodes = [];
        if(position >= nodes.length)        {
            nodes.push(element);
        } else    {
            nodes.splice(position, 0, element);
        }
        newState = dotProp.set(newState, this.state.pathObj.path, nodes);
        let fullPath = `${this.props.path}.${position}`;
        if(this.props.selected !== fullPath)    {
            this.props.setSelected(fullPath);
        }
        return newState;
    }

    move = (position, moveTo) => {
        if(this.state.pathObj.globals)      {
            getGlobals().then((response) => {
                const newState = this.moveNode(response.data, position, moveTo);
                patchPath({path: `globals.${this.props.globals.id}.structure`, value: newState.structure});
                this.props.setGlobals(newState);
            });
        } else {
            getPage({slug: this.props.page.slug}).then((response) => {
                const newState = this.moveNode(response.data, position, moveTo);
                patchPath({path: `pages.${this.props.page.id}.structure`, value: newState.structure});
                this.props.setPage(newState);
            });
        }
    }

    moveNode = (newState, position, moveTo) => {
        //if(!newState.structure)   {
            newState = this.state.pathObj.globals ? this.props.globals : this.props.page;
        //}
        let nodes = dotProp.get(newState, this.state.pathObj.path, []);
        const el = nodes[position];
        nodes.splice(position, 1);
        nodes.splice(moveTo, 0, el);
        newState = dotProp.set(newState, this.state.pathObj.path, nodes);
        let fullPath = `${this.props.path}.${moveTo}`;
        if(this.props.selected !== fullPath)    {
            this.props.setSelected(fullPath);
        }
        return newState;
    }

    render = () => {
        let selected = false;
        let selectedThis = false;
        const count = dotProp.get(this.props, this.props.path, []).length;
        const fullPath = `${this.props.path}.${this.props.position}`;
        if((this.props.admin && !this.props.preview) && this.props.selected)      {
            if(this.props.selected === fullPath)     {
                selectedThis = true;
            }
            if(this.props.selected.startsWith(fullPath))        {
                selected = true;
            }
        }
        let classname = "";
        if(selected) classname += "builder_grid_selected ";
        let node = dotProp.get(this.props, fullPath, false);
        let Block = false;
        let styles = {};
        if(node)        {
            if(node.classes)     {
                classname += Object.values(node.classes).join(" ");
            }
            Block = grids[node.grid].element;
            if(node.backgroundColor)    {
                styles.backgroundColor = node.backgroundColor;
            }
        }
        let classnameContainer = selected ? "builder_grid_hover container " : "container ";
        if(selectedThis) classnameContainer += " builder_grid_this_selected";
        return <div className={classname} style={styles}>
            <div className={classnameContainer}>

                {Block
                    ? <Block path={fullPath} node={node} match={this.props.match} history={this.props.history} />
                    : this.props.admin && !this.props.preview &&
                        <div
                            onClick={() => this.props.openDrawer({name: "grids", callback: this.selectedGridModal})}
                            className="builder_element_empty">
                            Kliknite da dodate blok
                        </div>}

                {selected  &&
                <div className="builder_grid_bottom">
                    <button className="mr-1 btn btn-info btn-sm"
                            onClick={() => this.props.openDrawer({name: "grids", callback: this.selectedGridModal})}>
                        <i className="fa fa-plus" title="Dodajte element iznad" /> Dodajte blok ispod
                    </button>
                    <button className="mr-1 btn btn-success btn-sm"
                            title="Podešavanja"
                        onClick={() => this.props.setSelected(fullPath)}>
                        <i className="fa fa-cog" />
                    </button>
                    {this.props.position > 0 &&
                    <button className="mr-1 btn btn-info btn-sm"
                        title="pomeri blok iznad prethodnog"
                        onClick={() => this.move(this.props.position, this.props.position - 1)}>
                        <i className="fa fa-chevron-up" />
                    </button>}

                    {((this.props.position + 1) < count) &&
                    <button
                        className="btn btn-info btn-sm"
                        title="pomeri blok ispod sledećeg"
                        onClick={() => this.move(this.props.position, this.props.position + 1)}>
                        <i className="fa fa-chevron-down" />
                    </button>}
                </div>}
            </div>
        </div>
    };
}
const mapStateToProps = state => ({
    admin: state.admin,
    page: state.page,
    globals: state.globals,
    selected: state.selected,
    preview: state.preview
});
const mapDispatchToProps = {
    setSelected: setSelected,
    setPage: setPage,
    setGlobals: setGlobals,
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Grid);
