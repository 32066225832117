import React from "react";
import {getProducts, putProductRecommendations} from "../../../providers/hooks";
import update from "react-addons-update";
import {storage} from "../../../providers/helpers";
import Overlay from "../../framework/Overlay";


class Recommendations extends React.Component {

    state =  {
        show: false,
        products: [],
        selected: this.props.value || []
    }

    add = (item) => {
        const newState = update(this.state, {selected: {$push: [item]}});
        this.setState(newState);
        this.props.onChange({target: {name: this.props.name, value: newState.selected}});
        if(this.props.product)     {
            putProductRecommendations({recommendations: newState.selected, product: this.props.product}).then((response) => {
                this.setState({...this.state, selected: response.data});
            });
        }
    }
    remove = (item) => {
        var r = window.confirm("Želite da obrišete proizvod?");
        if (r !== true) {
            return false;
        }
        const newSelected = this.state.selected.filter(prod => prod.id !== item.id);
        this.setState({...this.state, selected: newSelected});
        this.props.onChange({target: {name: this.props.name, value: newSelected}});
        if(this.props.product)     {
            putProductRecommendations({recommendations: newSelected, product: this.props.product}).then((response) => {
                this.setState({...this.state, selected: response.data});
            });
        }
    }

    autocomplete = (e) => {
        const val = e.target.value;
        if(val === "")  {
            this.setState({...this.state, products: []});
            return false;
        }
        getProducts({search: val, limit: 10}).then((response) => {
            this.setState({...this.state, products: response.data.data, show: true});
        });
    }

    render = () => {
        const selected = this.state.selected.map(item => item.id);
        return <>
            <div className="position-relative" style={{zIndex: 20}}>
                <input
                    onChange={this.autocomplete}
                    type="text" className="form-control"
                    placeholder="ime proizvoda..." autoComplete="off" />

                {this.state.show &&
                <div className="autocomplete bg-white" style={{color: "black"}}>
                    {this.state.products.map((item) => {
                        if(selected.includes(item.id)) return "";
                        return <div key={item.id} className="m-2 d-flex align-items-center">
                            <div className="mr-3" style={{width: 70}}>
                                <div className="bcg_image"
                                     style={{backgroundImage: `url(${storage(item.image_name)})`}} />
                            </div>
                            <div className="mr-3">
                                <strong> {item.name} </strong>
                            </div>
                            <button
                                onClick={() => this.add(item)}
                                className="btn btn-primary btn-sm">
                                dodajte
                            </button>
                        </div>
                    })}
                </div>}

                <div>
                    {this.state.selected.map((item) => {
                        return <div key={item.id} className="m-2 d-flex align-items-center border">
                            <div className="mr-3" style={{width: 60}}>
                                <div className="bcg_image"
                                     style={{backgroundImage: `url(${storage(item.image_name)})`}} />
                            </div>
                            <div className="mr-3">
                                <strong> {item.name} </strong>
                            </div>
                            <button
                                onClick={() => this.remove(item)}
                                className="btn btn-danger btn-sm">
                                <i className="fa fa-close" />
                            </button>
                        </div>
                    })}
                </div>
            </div>

            <Overlay zIndex={3} show={this.state.show} close={() => this.setState({...this.state, show: false})} />
        </>
    };

    componentDidMount() {

    }
}

export default Recommendations;
