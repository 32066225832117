import React from "react";
import {elements} from "./exports";
import {Link} from "react-router-dom";
import dotProp from "dot-prop-immutable"
import {insertPageNode, setPage} from "../store/actions/pageActions";
import {connect} from "react-redux";
import {setSelected} from "../store/actions/selectedActions";
import {insertGlobalsNode, setGlobals} from "../store/actions/globalsActions";
import {Path} from "./Path";
import {getGlobals, getPage, patchPath} from "../providers/hooks";
import {merge} from "../providers/helpers";
import {openDrawer} from "../store/actions/drawersActions";


class Looper extends React.Component {


    state = {
        pathObj: Path(this.props.path),
        elementsModal: false,
        splicePosition: false
    }

    openElementsModal = (position) => {
        this.setState({...this.state, elementsModal: true, splicePosition: position});
        this.props.openDrawer({name: "elements", callback: this.selectedElementsModal});
    }
    closeElementsModal = () => {
        this.setState({...this.state, elementsModal: false, splicePosition: false});
    }
    selectedElementsModal = (element) => {
        if(this.state.pathObj.globals)      {
            getGlobals().then((response) => {
                const newState = this.insertNode(response.data, element);
                this.props.setGlobals(newState);
                patchPath({path: `globals.${this.props.globals.id}.structure`, value: newState.structure});
            });
        }
        else        {
            getPage({slug: this.props.page.slug}).then((response) => {
                const newState = this.insertNode(response.data, element);
                this.props.setPage(newState);
                patchPath({path: `pages.${this.props.page.id}.structure`, value: newState.structure});
            });
        }
    }

    insertNode = (overrideState, element) => {
        let newState = this.state.pathObj.globals ? this.props.globals : this.props.page;
        newState = merge(newState, overrideState);
        let fullPath = `${this.props.path}.${this.state.splicePosition}`;
        let nodes = dotProp.get(newState, this.state.pathObj.path, []);
        if(!nodes) nodes = [];
        if(this.state.splicePosition >= nodes.length)        {
            nodes.push(element);
        }
        else    {
            nodes.splice(this.state.splicePosition, 0, element);
        }
        newState = dotProp.set(newState, this.state.pathObj.path, nodes);
        if(this.props.selected !== fullPath)    {
            this.props.setSelected(fullPath);
        }
        return newState;
    }

    move = (position, moveTo) => {
        if(this.state.pathObj.globals)      {
            getGlobals().then((response) => {
                const newState = this.moveNode(response.data, position, moveTo);
                this.props.setGlobals(newState);
                patchPath({path: `globals.${this.props.globals.id}.structure`, value: newState.structure});
            });
        }
        else    {
            getPage({slug: this.props.page.slug}).then((response) => {
                const newState = this.moveNode(response.data, position, moveTo);
                this.props.setPage(newState);
                patchPath({path: `pages.${this.props.page.id}.structure`, value: newState.structure});
            });
        }
    }

    moveNode = (overrideState, position, moveTo) => {
        let newState = this.state.pathObj.globals ? this.props.globals : this.props.page;
        newState = merge(newState, overrideState);
        let fullPath = `${this.props.path}.${moveTo}`;
        let nodes = dotProp.get(newState, this.state.pathObj.path, []);
        const el = nodes[position];
        nodes.splice(position, 1);
        nodes.splice(moveTo, 0, el);
        newState = dotProp.set(newState, this.state.pathObj.path, nodes);
        if(this.props.selected !== fullPath)    {
            this.props.setSelected(fullPath);
        }
        return newState;
    }


    render = () => {
        let path = this.props.path;
        let nodes = dotProp.get(this.props, path, []);
        return <div>
            {!nodes || nodes.length === 0
                ? this.props.admin && !this.props.preview &&
                    <div
                        onClick={() =>  this.openElementsModal(0)}
                        className="builder_element_empty">
                        Kliknite da dodate element
                    </div>
                : nodes.map((node, i) => {
                    let spread = {position: i, node: node};
                    let Element = elements[node.element].element;
                    let element = <Element {...spread} />;
                    let fullPath = `${path}.${i}`;

                    if(node.route || node.href) {
                        let blank = {};
                        if(node.blank)      {
                            blank.target = "_blank";
                            blank.rel = "noopener noreferrer"
                        }
                        if(this.props.admin && !this.props.preview)      {
                            blank.onClick = e => e.preventDefault();
                        }
                        const classname = node.classes?.fontColor || {};
                        if(node.route)      {
                            element = <Link to={"/" + node.route} {...blank} className={classname}>
                                {element}
                            </Link>
                        }
                        else {
                            element = <a href={node.href || "#"} {...blank} className={classname}>
                                {element}
                            </a>
                        }
                    }
                    if ((this.props.admin && !this.props.preview) && this.props.selected === fullPath)   {
                        const classname = this.props.inline ? "builder_element_hover_active looper_inline" : "builder_element_hover_active";
                        return <div key={i} className={classname}>
                            <div className="builder_element_settings_top">
                                <button className="btn builder_element_plus_top"
                                      title="dodaj element iznad"
                                      onClick={() => this.openElementsModal(i)}>
                                    <i className="fa fa-plus"/>
                                </button>

                                {i > 0 &&
                                <button className="btn ml-1"
                                        title="pomeri element iznad prethodnog"
                                        onClick={() => this.move(i, i - 1)}>
                                  <i className="fa fa-chevron-up" />
                                </button>}
                            </div>

                            {element}

                            <div className="builder_element_settings_bottom">
                                <button className="btn builder_element_plus_bottom"
                                      title="dodaj element ispod"
                                      onClick={() => this.openElementsModal(i + 1)}>
                                    <i className="fa fa-plus"/>
                                </button>
                                {(i + 1) < nodes.length  &&
                                <button
                                    title="pomeri element ispod sledećeg"
                                    className="btn ml-1" onClick={() => this.move(i, i + 1)}>
                                    <i className="fa fa-chevron-down" />
                                </button>}
                            </div>
                        </div>;
                    }

                    if(this.props.admin && !this.props.preview)      {
                        const classname = this.props.inline ? "builder_element_hover looper_inline" : "builder_element_hover";
                        return <div key={i} className={classname}
                                    onClick={() => this.props.setSelected(fullPath)}>
                            {element}
                        </div>;
                    }
                    const classname = this.props.inline ? "looper_inline" : "";
                    return <div key={i} className={classname}>
                        {element}
                    </div>
                })
            }
        </div>
    };
}
const mapStateToProps = state => ({
    page: state.page,
    globals: state.globals,
    admin: state.admin,
    selected: state.selected,
    preview: state.preview
});
const mapDispatchToProps = {
    setSelected: setSelected,
    setGlobals: setGlobals,
    setPage: setPage,
    insertGlobalsNode: insertGlobalsNode,
    insertPageNode: insertPageNode,
    openDrawer: openDrawer
};
export default connect(mapStateToProps, mapDispatchToProps)(Looper);
