import React from "react";
import LeftMenu from "./LeftMenu";
import {setCounter} from "../../../store/actions/counterActions";
import {connect} from "react-redux";
import {getOrdersCount} from "../../../providers/hooks";
import {Link} from "react-router-dom";


class CmsLayout extends React.Component {

    render = () => {
        const Component = this.props.component;
        return <div className="d-flex">

            <LeftMenu history={this.props.history} counter={this.props.counter} />

            <div className="flex-grow-1">
                <div className="container-fluid text-right mt-2">
                    <a href="/" target="_blank" rel="noreferrer"
                       className="btn btn-primary btn-sm">Idite na sajt <i className="fa fa-arrow-right" /> </a>
                </div>

                <Component
                    item={this.props.item}
                    history={this.props.history}
                    match={this.props.match} />
            </div>
        </div>
    };

    componentDidMount() {
        document.body.className = "cms";
        getOrdersCount().then((response) => {
            this.props.setCounter({name: "ordersCount", value: response.data});
        })
    }
}
const mapStateToProps = state => ({
    counter: state.counter
});
const mapDispatchToProps = {
    setCounter: setCounter
};
export default connect(mapStateToProps, mapDispatchToProps)(CmsLayout);
