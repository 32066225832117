import React from "react";

class Loader extends React.Component {

    state = {

    }

    render = () => {
        return <div className="d-flex justify-content-center align-items-center"
                    style={{height: this.props.height ? this.props.height : "auto"}}>
            <div className="text-center">
                <div className="d-flex justify-content-center mb-1">
                    <div className="loader" />
                </div>
            </div>
        </div>

    };
}

export default Loader;
