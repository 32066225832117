

export const register = async () => {
    if (!('serviceWorker' in navigator)) {
        return null;
    }
    const swUrl = `${process.env.PUBLIC_URL}/serviceWorker.js`;
    return await navigator.serviceWorker.register(swUrl);
};

export function postMessage(subject, payload) {
    if(navigator.serviceWorker.controller)  {
        navigator.serviceWorker.controller.postMessage({subject: subject, payload: payload});
    }
}
