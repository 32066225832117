import React from "react";

class Text extends React.Component {

    render = () => {
        let text = this.props.node.text || "Lorem Ipsum";

        let classname = "prewrap ";
        if(this.props.node.classes)     {
            if(this.props.node.route || this.props.node.href) {
                //this.props.node.classes.fontColor = "";
            }
            classname += Object.values(this.props.node.classes).join(" ");
        }
        let iconLeft = "";
        let iconRight = "";
        if(this.props.node.icon)     {
            if(this.props.node.iconRight)       {
                iconRight = <i className={this.props.node.icon + " ml-1"} />
            }
            else    {
                iconLeft = <i className={this.props.node.icon + " mr-1"} />
            }
            if(this.props.node.iconOnly)     {
                text = ""
            }
        }

        return <div className={classname} style={this.props.node.style || {}}>
            {iconLeft} {text} {iconRight}
        </div>
    };

}
export default Text;
