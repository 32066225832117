import React from 'react';
import {connect} from "react-redux";
import {categoryBreadcrumbs} from "../../../providers/helpers";
import LiLink from "../../../views/elements/LiLink";


class Categories extends React.Component {

    render = () => {
        let categories = [], breadcrumbs = [];
        if(!this.props.category)     {
            categories = this.props.categories;
            breadcrumbs = [];
        } else  {
            categories = this.props.category.children;
            breadcrumbs = categoryBreadcrumbs(this.props.category.parent, this.props.categories);
            if(this.props.page.category && this.props.page.category !== "sve")      {
                let holder = [], status = false;
                for(let i=0; i<breadcrumbs.length; i++)     {
                    const item = breadcrumbs[i];
                    if(item.slug === this.props.page.category) {
                        status = true;
                    }
                    if(status)  holder.push(item);
                }
                breadcrumbs = holder;
            }
        }
        return  <div className="link_button font-weight-bold">
            <div>
                {this.props.category && this.props.page.category === "sve" &&
                <div>
                    <LiLink to={`/${this.props.object.page}`}>
                       <i className="fa fa-angle-right mr-1" /> Sve
                    </LiLink>
                </div>}

                {breadcrumbs.length > 0 &&
                <div className="mb-4">
                    {breadcrumbs.map((item) => {
                        return <div key={item.id}>
                            <LiLink to={`/${this.props.object.page}/${item.slug}`}>
                                <i className="fa fa-angle-right mr-1" /> {item.name}
                            </LiLink>
                        </div>
                    })}
                </div>}
            </div>

            <div className="builder_title font-weight-bold mb-3 font_4">
                <span>
                    {this.props.category ? this.props.category.name : "KATEGORIJE"}
                </span>
            </div>

            {categories.map((item) => {
                return <div key={item.id}>
                    <LiLink to={`/${this.props.object.page}/${item.slug}`}>
                        {item.name}
                    </LiLink>
                </div>
            })}
        </div>
    };
}
const mapStateToProps = state => ({
    page: state.page,
    categories: state.categories,
});
export default connect(mapStateToProps)(Categories);
