import {ADD_TO_BASKET, DELETE_FROM_BASKET, EMPTY_BASKET, SET_BASKET} from "../actions/basketActions";
import {randString} from "../../providers/helpers";


function load() {
    let basket = localStorage.getItem('basket');
    if(!basket)
        return [];
    let parsed;
    try {
        parsed = JSON.parse(basket);
    }
    catch(err) {
        localStorage.removeItem('basket');
        return [];
    }
    if(!Array.isArray(parsed))      {
        localStorage.removeItem('basket');
        return [];
    }
    return parsed;
}

export default function(state = load(), {type, payload})      {
    switch(type)        {
        case SET_BASKET:
            localStorage.setItem('basket', JSON.stringify(payload));
            return payload;
        case ADD_TO_BASKET:
            let rand = randString();
            let storageObj = {id: payload.size, qty: payload.qty, filters: payload.filters, unique: rand};
            let newStore = [...state, storageObj];
            localStorage.setItem('basket', JSON.stringify(newStore));
            return newStore;
        case DELETE_FROM_BASKET:
            let products = state.filter(item => item.unique !== payload.unique);
            localStorage.setItem('basket', JSON.stringify(products));
            return products;
        case EMPTY_BASKET:
            localStorage.removeItem('basket');
            return [];
        default:
            return state;
    }
}