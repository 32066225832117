import React from "react";
import {getProduct, server} from "../../../providers/hooks";
import Loader from "../../../views/elements/Loader";
import Page404 from "../../../views/elements/Page404";
import {calculateDiscount, categoryBreadcrumbs, processPrice} from "../../../providers/helpers";
import {connect} from "react-redux";
import LiLink from "../../../views/elements/LiLink";
import ProductGallery from "./ProductGallery";
import Price from "../../theme/Price";
import ProductRender from "../../theme/ProductRender";
import Qty from "./Qty";
import {addToBasket} from "../../../store/actions/basketActions";
import {openDrawer} from "../../../store/actions/drawersActions";
import routes from "../../../app/routes";
import Share from "./Share";
import pdf from "../../../assets/images/pdf.png";
import {seoReset} from "../../../providers/seoProvider";
import SettingsButton from "../../fragments/SettingsButton";
import ProductsCarousel from "../ProductsCarousel";
import ProductsRecommendedCarousel from "./ProductsRecommendedCarousel";

class Product extends React.Component {

    state = {
        loading: false,
        product: null,
        size: false,
        qty: 1,
        inBasket: false
    }

    changeSize = (e, sizeId) => {
        e.preventDefault();
        this.setState({...this.state, size: sizeId});
        window.scrollTo({ top: 200, behavior: 'smooth' });
    }

    addToBusket = () => {
        this.props.addToBasket({size: this.state.size, qty: this.state.qty, filters: null});
        this.setState({...this.state, qty: 1, inBasket: true});
        this.props.openDrawer("basket");
        let t = this;
        setTimeout(function () {
            t.setState({...t.state, inBasket: false});
        }, 3000);
    };

    render = () => {
        if(this.state.loading)      {
            return <Loader height={300} />
        }
        if(!this.state.product)     {
            return <Page404 />
        }
        const product = this.state.product;
        const size = product.sizes.find(item => parseInt(item.id) === parseInt(this.state.size));
        return <div>
            <SettingsButton path={this.props.path} />

            <h1 className="d-md-none text-center font_3 p-2 mb-3">
                {size
                    ? size.name
                    : product.name}
            </h1>

            {product.categories.length > 0 &&
            <div className="mb-4 d-none d-md-block">
                {categoryBreadcrumbs(product.categories[0].id, this.props.categories).map((item, i) => {
                    return <span key={item.id} className="font_08">
                        <LiLink to={routes.shop.route + "/" + item.slug}>{item.name}</LiLink>
                        &nbsp; > &nbsp;
                </span>
                })}
                <span className="font_08">{product.name}</span>
            </div>}

            <div className="row mb-3">
                <div className="col-md-6 pr-md-5">
                    <div className="mr-md-5 position-relative">
                        <div className="product_tags" style={{zIndex: 5}}>
                            {size && size.discount_price && size.price &&
                            <div>-{calculateDiscount(size)}%</div>}
                            {product.new && <div>NOVO</div>}
                            {product.action && <div>AKCIJA</div>}
                        </div>

                        <ProductGallery product={product}  />
                    </div>
                </div>

                <div className="col-md-6">
                    <div className="mb-2">
                        <div className="gray font_2">
                            {product.brand &&
                            <div className="d-md-inline-block mb-2 mb-md-0"> Brend: {product.brand} </div>}
                        </div>
                    </div>

                    <h1 className="font-weight-bold d-none d-md-block font_4 mb-4">
                        {size
                            ? size.name
                            : product.name}
                    </h1>

                    {product.description &&
                    <div className="gray mb-4 pre_wrap">{product.description}</div>}

                    {product.sizes.length > 1 &&
                    <div>
                        <div className="font-weight-bold mb-2">Izaberite veličinu</div>
                        <select
                            value={this.state.size}
                            onChange={(e) => this.setState({...this.state, size: e.target.value})}
                            className="form-control">
                            <option value={false}> Izaberite </option>
                            {product.sizes.map((item) => {
                                return <option key={item.id} value={item.id}>
                                    {item.name} --- {processPrice(item)}
                                </option>
                            })}
                        </select>
                    </div>}

                    {size &&
                    <div className="mt-3">
                        {size.code &&
                        <div className="mb-3 font-weight-bold">Šifra: {size.code}</div>}

                        <div className="d-flex justify-content-between align-items-center font-weight-bold">
                            <div>
                                Cena:
                                <div className="font_primary font_6 mb-3">
                                    <Price item={size} />
                                    {size.discount_price &&
                                    <del className="gray ml-2 font-weight-normal font_2"><Price price={size.price} /></del>}
                                </div>
                            </div>
                            <div style={{color: "green"}}>
                                NA STANJU
                            </div>
                        </div>

                        {this.state.inBasket
                            ?  <div className="alert alert-success">
                                <div className="font_2 font-weight-bold"> Dodato u korpu </div>
                            </div>
                            : <div className="d-flex">
                                {processPrice(size) > 0 &&
                                <>
                                <Qty
                                    max={10}
                                    value={this.state.qty}
                                    onChange={(val) => this.setState({...this.state, qty: val})} />

                                <button onClick={this.addToBusket} className="btn btn-success pl-5 pr-5">
                                    Dodajte u korpu
                                </button>
                                </>}
                            </div>}

                        <div className="gray mt-3 mb-3">
                            Prava potrošača: Zagarantovana sva prava kupaca po osnovu zakona o zaštiti potrošača.
                        </div>

                        <Share product={product} />
                    </div>}
                </div>
            </div>

            {product.specifications.length > 0 &&
            <div>
                <div className="font-weight-bold bcg_secondary p-3">
                    SPECIFIKACIJA:
                </div>
                <table className="table table-bordered table-odd">
                    <tbody>
                    {product.specifications.map((item) => {
                        return <tr key={item.id}>
                            <td>
                                {item.name}
                            </td>
                            <td>
                                {item.value}
                            </td>
                        </tr>
                    })}
                    </tbody>
                </table>

            </div>}

            {product.sizes.length > 1 &&
            <div className="mb-5">
                <div className="font-weight-bold bcg_secondary p-3 mb-2">
                    VELIČINE:
                </div>
                <div className="link_button">
                    {product.sizes.map((item) => {
                        return <div key={item.id} className="mb-2">
                            <a href="/" onClick={(e) => this.changeSize(e, item.id)}>
                                {item.code} --- {item.name} --- <Price item={item} />
                            </a>
                        </div>
                    })}
                </div>
            </div>}

            {product.pdfs.length > 0 &&
            <div>
                <div className="font-weight-bold bcg_secondary p-3">
                    TEHNIČKA SPECIFIKACIJA:
                </div>
                {product.pdfs.map((item) => {
                    return <div key={item.id} className="p-3 font_3 link_button">
                       <a
                           rel="noopener noreferrer"
                           href={server('fajlovi/' + item.name)} target="_blank">
                           <div className="d-flex justify-content-between align-items-center">
                               <div>
                                   <img src={pdf} alt={item.orig_name} width={60} className="mr-3" />
                                   {item.download_name || item.orig_name}
                               </div>
                               <span className="font_primary p-2">{item.download_type}</span>
                           </div>
                       </a>
                    </div>
                })}
            </div>}

            {product.recommendations.length > 0 &&
            <div>
                <div className="font-weight-bold bcg_secondary p-3 mb-3">
                    POVEZANI PROIZVODI:
                </div>
                <ProductsRecommendedCarousel products={product.recommendations} />
            </div>}
        </div>
    };

    loadProduct = async () => {
        const id = this.props.match.params.id;
        if (!id) return false;
        this.setState({...this.state, loading: true, product: false});
        getProduct({id: id}).then((response) => {
            let size = (response.data.product && response.data.product.sizes.length === 1) ? response.data.product.sizes[0].id :  false;
            seoReset(response.data.product, response.data.product?.name);
            this.setState({...this.state, loading: false, product: response.data.product, size: size});
        })

    }
    componentDidMount() {
        this.loadProduct();
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        if(this.props.match.params.id !== prevProps.match.params.id)            {
            this.loadProduct();
        }
    }
}
const mapStateToProps = state => ({
    categories: state.categories,
});
const mapDispatchToProps = {
    openDrawer: openDrawer,
    addToBasket: addToBasket
};
export default connect(mapStateToProps, mapDispatchToProps)(Product);
