import React from "react";
import {connect} from "react-redux";
import Modal from "../../../views/framework/Modal";
import {storage} from "../../../providers/helpers";
import Lightbox from "react-image-lightbox";
import moment from "moment";


class PopupModal extends React.Component {

    state = {
        open: false
    }

    render = () => {
         return  <div>
             {this.state.open &&
             <Lightbox
                 mainSrc={storage(this.props.globals.popup_image_name,true)}
                 onCloseRequest={() => this.setState({...this.state, open: false})}/>}
         </div>
    };

    componentDidMount() {
        if(!this.props.globals.popup || !this.props.globals.popup_image_id) return;
        let storageTime = localStorage.getItem("popup");
        if(storageTime)      {
            const storage = moment.utc(storageTime, 'DD-MM-YYYY HH:mm:ss')
            const set = moment.duration(moment.utc().diff(storage)).asMinutes();
            console.log(set)
            if(isNaN(set))     {
                localStorage.removeItem("popup");
            }
            if(set > 5) {
                localStorage.removeItem("popup");
            } else {
                return false;
            }
        }
        localStorage.setItem("popup", moment.utc().format('DD-MM-YYYY HH:mm:ss').toString());
        setTimeout(() => {
            this.setState({...this.state, open: true});
        }, 1000);
    }
}
const mapStateToProps = state => ({
    globals: state.globals
});
const mapDispatchToProps = {

};
export default connect(mapStateToProps, mapDispatchToProps)(PopupModal);
