import React from "react";
import Loader from "../../../views/elements/Loader";
import {getDownload, server} from "../../../providers/hooks";
import pdf from "../../../assets/images/pdf.png"
import update from "react-addons-update";
import SettingsButton from "../../fragments/SettingsButton";


class Download extends React.Component {

    state = {
        loading: true,
        download: [],
        form: {
            name: "",
            brand: ""
        },
        brands: [],
        types: []
    }

    editField = (e) => {
        const field = e.target.name;
        let value = e.target.value;
        const newState = update(this.state, {form: {[field]: {$set: value}} });
        this.setState(newState);
        this.load(newState.form);
    }

    render = () => {
        if(this.state.loading)       {
            return <Loader height={300} />
        }
        const form = this.state.form;
        return <div>
            <SettingsButton path={this.props.path} />

            <div>
                <form className="row mb-4 m-0 justify-content-center gray_block">
                    <div className="col-md-3">
                        <div className="font-weight-bold">Naziv</div>
                        <input
                            name="name" value={form.name}
                            autoComplete="off"
                            onChange={this.editField}
                            type="text" className="form-control mb-2" placeholder="Unesite..." />
                    </div>
                    <div className="col-md-3">
                        <div className="font-weight-bold">Brend</div>
                        <select
                            name="brand" value={form.brand}
                            className="form-control mb-2"
                            onChange={this.editField}>
                                <option value="">Sve</option>
                                {this.state.brands.map((item) => {
                                    return <option key={item.brand} value={item.brand}>
                                        {item.brand} ({item.cnt})
                                    </option>
                                })}
                        </select>
                    </div>
                    <div className="col-md-3">
                        <div className="font-weight-bold">Tipe</div>
                        <select
                            name="type" value={form.type}
                            className="form-control"
                            onChange={this.editField}>
                            <option value="">Sve</option>
                            {this.state.types.map((item) => {
                                if(!item.type) return null;
                                return <option key={item.type} value={item.type}>
                                    {item.type} ({item.cnt})
                                </option>
                            })}
                        </select>
                    </div>
                </form>


                    {this.state.download.length === 0
                        ? <div className="font-weight-bold font_3 text-center">Nema fajlova</div>
                        :   <div className="row align-items-stretch">
                            {this.state.download.map((item) => {
                                return  <div key={item.id} className="col-6 col-md-2">
                                    <div className="bordered h-100 p-2 text-center">
                                        <a rel="noopener noreferrer" href={server('fajlovi/' + item.file_name)} target="_blank">
                                            <img src={pdf} className="img-fluid" alt={item.name} />
                                            <div className="font-weight-bold mb-2">
                                                {item.name}
                                            </div>
                                            {item.type &&
                                            <div className="bcg_primary font-weight-bold font_09 p-2">
                                                {item.type}
                                            </div>}
                                        </a>
                                    </div>
                                </div>
                            })}
                        </div>
                    }
            </div>

        </div>
    };

    load = (form) => {
        getDownload(form).then((response) => {
            console.log(response.data)
            this.setState({...this.state, download: response.data.download, brands: response.data.brands, types: response.data.types, loading: false});
        });
    }

    componentDidMount() {
        this.load();
    }
}
export default Download;
