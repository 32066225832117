import React from "react";
import {EmailShareButton, TwitterShareButton, ViberShareButton,
    PinterestShareButton, TelegramShareButton, WhatsappShareButton} from "react-share";
import {EmailIcon, TwitterIcon, ViberIcon,
    PinterestIcon, TelegramIcon, WhatsappIcon} from "react-share";
import {storage} from "../../../providers/helpers";


class Share extends React.Component {

    render = () => {
        return  <div>
            <div className="mb-2"> Podelite: </div>
            <TwitterShareButton
                title={this.props.product.name}
                url={window.location.href} className="mr-2 mb-2">
                <TwitterIcon size={42} round={true}  />
            </TwitterShareButton>

            <ViberShareButton
                title={this.props.product.name}
                url={window.location.href} className="mr-2 mb-2">
                <ViberIcon size={42} round={true}  />
            </ViberShareButton>

            <PinterestShareButton
                media={storage(this.props.product.image_name, true)}
                description={this.props.product.name}
                url={window.location.href} className="mr-2 mb-2">
                <PinterestIcon size={42} round={true}  />
            </PinterestShareButton>

            <EmailShareButton
                subject={this.props.product.name}
                body={this.props.product.name}
                url={window.location.href} className="mr-2 mb-2">
                <EmailIcon size={42} round={true} />
            </EmailShareButton>

            <TelegramShareButton
                title={this.props.product.name}
                url={window.location.href} className="mr-2 mb-2">
                <TelegramIcon size={42} round={true}  />
            </TelegramShareButton>

            <WhatsappShareButton
                title={this.props.product.name}
                url={window.location.href} className="mr-2">
                <WhatsappIcon size={42} round={true}  />
            </WhatsappShareButton>
        </div>
    };

    componentDidMount() {

    }
}
export default Share;
