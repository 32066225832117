import React from "react";
import {connect} from "react-redux";
import Drawer from "../../../views/framework/Drawer";
import {closeDrawer} from "../../../store/actions/drawersActions";
import Looper from "../../Looper";
import routes from "../../../app/routes";
import {storage} from "../../../providers/helpers";
import LiLink from "../../../views/elements/LiLink";
import {Swipeable} from "react-swipeable";


class CategoriesDrawer extends React.Component {

    state = {
        category: this.props.categories[0],
        slide: false
    }

    slide = (cat) => {
        this.setState({...this.state, category: cat});
        setTimeout(() => {
            this.setState({...this.state, slide: true});
        }, 100);
    }

    changeCategory = (cat) => {
        this.setState({...this.state, category: cat});
    };

    item = (item) => {
        return <li className="d-flex mt-1" key={item.id}
                   onMouseEnter={() =>this.changeCategory(item)}>
            <LiLink
                onClick={() => this.props.closeDrawer("categories")}
                to={routes.singleCategory.route(item)}>
                <span className="font-weight-bold"> {item.name} </span>
            </LiLink>
            {item.children.length > 0 &&
            <button onClick={() =>this.slide(item)} className="d-md-none w-auto btn btn-sm pl-3 pr-3 btn-primary">
                <i className="fa fa-chevron-right" />
            </button>}
        </li>
    }

    render = () => {
        const drawer = this.props.drawer;
       return <Drawer
            anchor="left"
            width="80%"
            show={drawer.status}
            close={() => this.props.closeDrawer('categories')}>

           <div className="categories d-md-flex">
               <div className={this.state.slide ? "left_panel left_panel_slide" : "left_panel"}>
                   <div className="m-1">
                       <div className="border-bottom">
                           <Looper
                               path="globals.structure.categories drawer logo"
                               default={[{element: "image", classes: {textAlign: "text-center"}}]} />
                       </div>

                       <ul className="list-unstyled link_button">
                           {this.props.categories.map((item) => {
                               if(item.parent) return "";
                               return this.item(item);
                           })}
                       </ul>
                   </div>

                   <div className="categories_back d-md-none">
                       <button onClick={() => this.setState({...this.state, slide: false})}
                               className="btn btn-primary">
                           <i className="fa fa-chevron-left" /> Nazad
                       </button>
                   </div>
               </div>

               <div className="right_panel p-md-3 p-1">
                   <Swipeable onSwipedRight={() => this.setState({...this.state, slide: false})} className="h-100">
                   {this.state.category && this.state.category.children.length > 0 &&
                   <div className="row m-0">

                       {this.state.category.children.map((item) => {
                           return <div className="col-md-4 mb-3 p-0 p-md-3" key={item.id}>

                               {item.image_name &&
                               <img src={storage(item.image_name)} className="img-fluid mb-3" alt={item.name} />}

                               <div className="prewrap builder_title font_2">
                                   <span>
                                        <LiLink
                                            onClick={() => this.props.closeDrawer("categories")}
                                            to={routes.singleCategory.route(item)}>

                                       <span className="font-weight-bold"> {item.name} </span>
                                   </LiLink>
                                   </span>
                               </div>

                               <ul className="list-unstyled link_button mt-3">
                                   {item.children.map((child) => {
                                       return <li key={child.id} className="d-flex">
                                           <LiLink
                                               onClick={() => this.props.closeDrawer("categories")}
                                               to={routes.singleCategory.route(child)}>
                                               <span className="font-weight-bold"> {child.name} </span>
                                           </LiLink>
                                       </li>
                                   })}
                               </ul>
                           </div>
                       })}

                   </div>}
                   </Swipeable>
               </div>
           </div>

       </Drawer>
    };
}
const mapStateToProps = state => ({
    categories: state.categories,
    drawer: state.drawers.categories
});
const mapDispatchToProps = {
    closeDrawer: closeDrawer
}
export default connect(mapStateToProps, mapDispatchToProps)(CategoriesDrawer);
