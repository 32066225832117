import React from "react";
import {connect} from "react-redux";
import Slider from "../Slider";
import Classes from "../Classes";

class BackgroundSettings extends React.Component {

    onChange = (e) => {
        this.props.edit(e);
        this.props.compileStyles();
    }

    render = () => {
        const node = this.props.node;
       return <Slider header="POZADINA" className="mb-3">
               <div>
                   <div className="font-weight-bold mb-1"> Boja: </div>

                   <div className="d-flex align-items-center">
                   <input type="color"
                          name="backgroundColor"
                          onBlur={this.props.save}
                          onChange={this.props.edit}
                          value={node.backgroundColor || "#ffffff"} />
                       <label className="btn btn-sm btn-danger">
                           <input name="backgroundColor" value={false} onChange={this.props.save}
                                  style={{visibility: "hidden", width: 2}} type="checkbox" />
                           <i className="fa fa-close" />
                       </label>
                       <div> &nbsp; ili &nbsp; </div>
                       <Classes
                           name="bcgColor"
                           classes={node.classes}
                           onChange={this.props.save} />
                   </div>
               </div>
           </Slider>
    };
}
const mapStateToProps = state => ({
    globals: state.globals
});
export default connect(mapStateToProps)(BackgroundSettings);
