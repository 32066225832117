
export function payment_method(method) {
    const methods = {
        1: "Plaćanje prilikom preuzimanja",
        2: "Uplata na tekući račun",
        3: "Plaćanje platnom karticom"
    }
    if(methods[method]) return methods[method];
}

export function order_statuses() {
    return  {
        1: {title: "Na čekanju", color: "#ff9e00", value: 1},
        2: {title: "Primljeno", color: "#006fca", value: 2},
        3: {title: "Poslato", color: "#009a56", value: 3},
        4: {title: "Isporučeno", color: "#009a56", value: 4},
        5: {title: "Otkazano", color: "#d00000", value: 5},
    }
}
export function order_status(method) {
    const methods = order_statuses();
    if(methods[method]) return methods[method];
}