import React from 'react';
import {connect} from "react-redux";
import {deletePath, patchPath} from "../../../providers/hooks";
import {setMenu} from "../../../store/actions/menuActions";
import AddPage from "./AddPage";


class PagesItem extends React.Component   {

    state = {
        settings: false
    }

    delete = () => {
        var r = window.confirm("Želite da obrišete stranu?");
        if (r !== true) {
            return false;
        }
        deletePath({path: "pages." + this.props.item.id}).then((response) => {
           this.props.reload();
        })
    }

    changeHeader = (e) => {
        const checked = e.target.checked ? 1 : null;
        patchPath({path: `pages.${this.props.item.id}.header`, value: checked}).then(response => {
            this.props.reload();
        });
    }

    render = () => {
        let item = this.props.item;
        return <div>
            <div className="d-flex justify-content-between align-items-center">
                <div>
                    {this.props.collapseIcon}
                    {this.props.handler}
                    {item.name}
                </div>
                <button
                    onClick={() => this.setState({...this.state, settings: !this.state.settings})}
                    className="btn btn-primary btn-sm">
                    <i className="fa fa-gear" />
                </button>
            </div>
            {this.state.settings &&
            <div className="mt-3">
                <div className="mb-3">
                    <AddPage item={item} reload={this.props.reload} />
                </div>
                <div className="mb-3">
                    <AddPage parent={item.id} reload={this.props.reload} />
                </div>
                <div className="mb-3">
                    <label>
                        <input onChange={this.changeHeader}
                               type="checkbox" value={1} checked={item.header} /> Header
                    </label>

                </div>
                <button onClick={this.delete} className="btn btn-danger">
                    Obrišite
                </button>
            </div>}
        </div>;
    };
}
const mapStateToProps = state => ({
    menu: state.menu
});
const mapDispatchToProps = {
    setMenu: setMenu
};
export default connect(mapStateToProps, mapDispatchToProps)(PagesItem);
