import { combineReducers, createStore } from "redux";
import page from "./reducers/pageReducer";
import menu from "./reducers/menuReducer";
import globals from "./reducers/globalsReducer";
import selected from "./reducers/selectedReducer";
import admin from "./reducers/adminReducer";
import drawers from "./reducers/drawersReducer";
import categories from "./reducers/categoriesReducer";
import preview from "./reducers/previewReducer";
import basket from "./reducers/basketReducer";
import user from "../store/reducers/userReducer";
import counter from "../store/reducers/counterReducer";


const store = createStore(combineReducers({
    page: page,
    menu: menu,
    globals: globals,
    selected: selected,
    admin: admin,
    drawers: drawers,
    categories: categories,
    preview: preview,
    basket: basket,
    user: user,
    counter: counter,
}));

export default store;