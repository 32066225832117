import React from "react";


class Price extends React.Component   {

    state = {
        currency: "RSD"
    }

    format = (price) => {
        if(!price) return '';
        price = parseFloat(price).toFixed(2);
        let strPrice = String(price);
        if(price > 999)     {
            const pos = -6;
            strPrice = strPrice.slice(0, pos) + " " + strPrice.slice(pos);
        }
        if(price > 999999)     {
            const pos = -10;
            strPrice = strPrice.slice(0, pos) + " " + strPrice.slice(pos);
        }
        return strPrice.replace(".", ",");
    };

    render = () => {
        const item = this.props.item;
        const price = this.props.price;
        if(price !== undefined)       {
            return <span>{this.format(price) + " " + this.state.currency}</span>
        }
        if(item.min_price && item.max_price && item.min_price !== item.max_price)       {
            return <div> {this.format(item.min_price)} - {this.format(item.max_price) + " " + this.state.currency} </div>
        }
        if(item.discount_price)  {
            return <span>{this.format(item.discount_price) + " " + this.state.currency}</span>
        }
        if(item.price)  {
            return <span>{this.format(item.price) + " " + this.state.currency}</span>
        }
        return <div> {this.format(item.min_price) + " " + this.state.currency} </div>
    }
}
export default Price;
