import React from "react";
import routes from "../../../app/routes";
import {logoutAdmin} from "../../../providers/adminProvider";
import LeftMenuItem from "./LeftMenuItem";


class LeftMenu extends React.Component {

    logout = (e) => {
        e.preventDefault();
        logoutAdmin(true);
    }

    render = () => {
        return <>
            <div className="left_menu">
                <ul className="list-unstyled mt-3">
                    <LeftMenuItem
                        history={this.props.history}
                        item={routes.products} />

                    <LeftMenuItem
                        history={this.props.history}
                        item={routes.categories} />

                    <LeftMenuItem
                        history={this.props.history}
                        item={routes.filters} />

                    <LeftMenuItem
                        count={this.props.counter.ordersCount > 0 ? this.props.counter.ordersCount : ""}
                        history={this.props.history}
                        item={routes.orders} />

                    <LeftMenuItem
                        history={this.props.history}
                        item={routes.download} />

                    <LeftMenuItem
                        history={this.props.history}
                        item={routes.storage} />

                    <LeftMenuItem
                        history={this.props.history}
                        item={routes.users} />

                    <LeftMenuItem
                        history={this.props.history}
                        item={routes.newsletters} />

                    <LeftMenuItem
                        history={this.props.history}
                        item={routes.administrators} />

                    <LeftMenuItem
                        history={this.props.history}
                        item={routes.popup} />

                    <li>
                        <a href="/" onClick={this.logout}>Izlogujte se</a>
                    </li>

                </ul>
            </div>
            <div className="left_menu_buffer" />
        </>
    };
}

export default LeftMenu;
